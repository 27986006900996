import React from 'react';
import { Box, Typography, Checkbox } from '@mui/material';
import { Button, Dialog, LoadingButton, Pagination, SearchBox } from '../../';
import { PaginationInterface } from '../../types';

export interface AddLinkedItemFormProps {
  /**
   * Whether the dialog is visible or not
   */
  isVisible: boolean;
  /**
   * An array of items to display in the list
   */
  results: Array<unknown>;
  /**
   * The title of the dialog
   */
  title: string;
  /**
   * The aria-label for the search button
   */
  searchButtonAriaLabel: string;
  /**
   * The function to call when the dialog is closed
   */
  handleClose: () => void;
  /**
   * The submit button props {onClick, text, disabled, loading}
   */
  submitButton: {
    onClick: () => void;
    text: string;
    disabled: boolean;
    loading?: boolean;
  };
  /**
   * The pagination props {page, pageCount, onChange}
   */
  pagination: PaginationInterface;
  /**
   * The search text
   */
  searchFreeText: string;
  /**
   * The function to call when the search text changes
   */
  setSearchFreeText: (freeText: string) => void;
  /**
   * Whether the device is small or not
   */
  isSmallDevice: boolean;
  /**
   * The translation function
   */
  t: (key: string, options?: Record<string, string | number>) => string;
  /**
   * The template component to use to render the list items
   */
  listItemGenerator: (item: unknown, idx: number) => JSX.Element;
  /**
   * Optional click handler for Clear all button
   */
  onClearAll?: () => void;
  /**
   * The select all checkbox props {onChange, checked}
   */
  selectAllCheckbox?: {
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    checked: boolean;
  };
  /**
   * Any special component to display below the search box and above the list of items
   */
  renderSpecial?: () => JSX.Element;
}

/**
 * Displays a dialog with a search box and a paginated list of items. Used for linking one thing to another e.g linking a user to an asset or task
 */
export const AddLinkedItemForm: React.FC<React.PropsWithChildren<AddLinkedItemFormProps>> = ({
  results,
  isSmallDevice,
  pagination,
  searchFreeText,
  setSearchFreeText,
  title,
  searchButtonAriaLabel,
  handleClose,
  t,
  onClearAll,
  selectAllCheckbox,
  submitButton,
  isVisible,
  listItemGenerator,
  renderSpecial,
}: AddLinkedItemFormProps) => {
  const SubmitButton = (
    <LoadingButton
      color="primary"
      variant="contained"
      onClick={submitButton.onClick}
      data-testid="add-linked-item-submit-button"
      disabled={submitButton?.loading || submitButton.disabled}
      isLoading={submitButton?.loading}
    >
      {submitButton.text}
    </LoadingButton>
  );

  const PaginationElement = (
    <Pagination
      page={pagination.page}
      count={pagination.pageCount}
      limitCount={499}
      onChange={pagination.onChange}
      data-testid="add-linked-item-form-pagination"
      // dont' see another way to save space on mobile... see issue here https://github.com/mui/material-ui/issues/22380
      size={isSmallDevice ? 'small' : 'medium'}
      hideNextButton={isSmallDevice ? true : false}
      hidePrevButton={isSmallDevice ? true : false}
    />
  );

  return (
    <Dialog
      isSmallDevice={isSmallDevice}
      isOpen={isVisible}
      onClose={handleClose}
      title={title}
      contentHeight={isSmallDevice ? '70vh' : '60vh'}
      actions={
        <Box width="100%" display="flex" justifyContent="space-between">
          <Box width="20%" />
          <Box width="60%">{PaginationElement}</Box>
          <Box width="20%" display="flex" justifyContent="flex-end">
            {SubmitButton}
          </Box>
        </Box>
      }
    >
      <Box display="flex" sx={{ flexFlow: 'column', height: '100%' }}>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          {onClearAll ? (
            <Button variant="text" onClick={onClearAll} sx={{ mr: 4 }}>
              {t('ns.task_filter:clear_all_button')}
            </Button>
          ) : null}
          {selectAllCheckbox ? (
            <>
              <Typography>{t(`ns.common:checkbox:select_all`)}</Typography>
              <Checkbox
                checked={selectAllCheckbox.checked}
                onChange={selectAllCheckbox.onChange}
                data-testid="select-all"
              />
            </>
          ) : null}
        </Box>
        <SearchBox
          label={searchButtonAriaLabel}
          onSubmit={(searchBoxValue) => setSearchFreeText(searchBoxValue)}
          startValue={searchFreeText}
          searchButtonAria={searchButtonAriaLabel}
          disableSelfSpacing={true}
        />

        {renderSpecial && <Box>{renderSpecial()}</Box>}

        <Box sx={{ overflowY: 'auto', height: '100%' }}>
          {results &&
            results?.map((result, idx) => {
              return listItemGenerator(result, idx);
            })}
        </Box>
      </Box>
    </Dialog>
  );
};
