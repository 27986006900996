import React, { Suspense } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Route, BrowserRouter, Switch } from 'react-router-dom';
import { CompatRouter } from 'react-router-dom-v5-compat';
import { setupGriegIntercepts } from '@askporter/api-client';
import { GeneralErrorBoundary, Loading, StaticError, ToastProvider } from '@askporter/component-library';
import { LanguageProvider } from '@askporter/config-provider';
import { ThemedError } from '@askporter/rum';
import CheckForLocalStorage from './components/CheckForLocalStorage';
import CoreNavigation from './components/CoreNavigation';
import { RequireAuth } from './components/RequireAuth';
import AuthProvider from './providers/AuthProvider';
import ConfigProvider from './providers/ConfigProvider';
import MaintenanceModeDetector from './providers/MaintenanceModeDetector';
import { ThemeProvider } from './providers/Theme';
import { lazyWithRetry, config } from './utils';
import { clientConfig } from './utils/reactQuery';

const queryClient = new QueryClient(clientConfig);
setupGriegIntercepts(); // this should be incorporated into https://github.com/askporter/front-end/pull/642

const RedirectToAA: React.FC = () => {
  // See issue https://github.com/askporter/askagent-twilio-extensions/issues/145
  React.useEffect(() => {
    // eslint-disable-next-line functional/immutable-data
    window.location.href = '/aa/index.html';
  }, []);
  return null;
};

const PrivateRoutes = lazyWithRetry(() => import('./components/PrivateRouter'));
const PublicRouter = lazyWithRetry(() => import('./components/PublicRouter'));

export const App: React.FC<React.PropsWithChildren<unknown>> = () => (
  <>
    {/* Global catch-all error boundary. This will render a static error page with the default askporter theme. */}
    {/* This error boundary will be triggered if any of the providers below (until the next error boundary) throw a JS error. */}
    <GeneralErrorBoundary fallback={({ error }) => <StaticError error={error?.toString()} />}>
      <CheckForLocalStorage>
        <QueryClientProvider client={queryClient}>
          <ConfigProvider>
            <MaintenanceModeDetector>
              <LanguageProvider>
                <BrowserRouter>
                  <CompatRouter>
                    <ThemeProvider>
                      {/* This error page will be themed and translated */}
                      <GeneralErrorBoundary
                        fallback={({ error }) => (
                          <Suspense fallback={<Loading />}>
                            <ThemedError errMsg={error?.toString()} navigation={<CoreNavigation />} />
                          </Suspense>
                        )}
                      >
                        <AuthProvider>
                          <ToastProvider>
                            <Suspense fallback={<Loading />}>
                              <Switch>
                                <Route path="/aa">
                                  <RedirectToAA />
                                </Route>
                                <Route path="/app">
                                  <RequireAuth>
                                    <PrivateRoutes />
                                  </RequireAuth>
                                </Route>
                                <Route path="/">
                                  <PublicRouter />
                                </Route>
                              </Switch>
                            </Suspense>
                          </ToastProvider>
                        </AuthProvider>
                      </GeneralErrorBoundary>
                    </ThemeProvider>
                  </CompatRouter>
                </BrowserRouter>
              </LanguageProvider>
            </MaintenanceModeDetector>
          </ConfigProvider>
          {/* By default, React Query Devtools are not included in production bundles. We do not want ReactQueryDevTools to
        render when we run unit tests, so only render if test mode is not true. We also don't want this to show when running Cypress in CI. */}
          {config.ENV === 'development' && !config.DEV_DISABLE_REACT_TOOLS && !config.IS_CI && (
            <ReactQueryDevtools initialIsOpen={false} position={'bottom-right'} />
          )}
        </QueryClientProvider>
      </CheckForLocalStorage>
    </GeneralErrorBoundary>
  </>
);
