export function parseURLQueryParams<T>(searchStateKeys: Array<string>): T {
  const query = new URLSearchParams(window.location.search);

  const parsedParams: Record<string, unknown> = {};

  searchStateKeys.forEach((key) => {
    const value = query.get(key);

    if (value !== null) {
      switch (key) {
        case 'freeText':
          parsedParams[key] = value;
          break;

        case 'page':
          try {
            if (isNaN(parseInt(value))) throw new Error('Not a number');

            parsedParams[key] = parseInt(value);
          } catch (error) {
            parsedParams[key] = 1;
          }
          break;

        case 'sort':
          parsedParams[key] = value;
          break;

        case 'searchFilter':
        case 'filter':
        case 'extendedMetadataStats':
        case 'additionalInformation':
          try {
            parsedParams[key] = JSON.parse(decodeURIComponent(value));
          } catch (err) {
            // if the params are not parsable then they are likely to be invalid, so we ignore them
          }
          break;

        default:
          break;
      }
    }
  });

  return parsedParams as T;
}
