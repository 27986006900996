export type SearchUserSort = 'GIVEN_NAME_DESC' | 'GIVEN_NAME_ASC' | 'FAMILY_NAME_DESC' | 'FAMILY_NAME_ASC' | 'CREATED_DESC' | 'CREATED_ASC';

export const SearchUserSort = {
  GIVENNAMEDESC: 'GIVEN_NAME_DESC' as SearchUserSort,
  GIVENNAMEASC: 'GIVEN_NAME_ASC' as SearchUserSort,
  FAMILYNAMEDESC: 'FAMILY_NAME_DESC' as SearchUserSort,
  FAMILYNAMEASC: 'FAMILY_NAME_ASC' as SearchUserSort,
  CREATEDDESC: 'CREATED_DESC' as SearchUserSort,
  CREATEDASC: 'CREATED_ASC' as SearchUserSort,
};
