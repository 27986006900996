import { ExportResourceNames } from '../types';

export const generateExportFileName = (type: ExportResourceNames): string => {
  const date = new Date();
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
  const day = date.getDate().toString().padStart(2, '0');
  const fullYear = date.getFullYear().toString();
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');

  return `export-${type}-${fullYear}-${month}-${day}-${hours}-${minutes}`;
};
