import React from 'react';
import { TreeView as MuiTreeView, MultiSelectTreeViewProps } from '@mui/lab';

interface TreeViewProps extends MultiSelectTreeViewProps {
  /**
   * The TreeView items (nodes)
   */
  children?: JSX.Element | JSX.Element[];
  /**
   * How spaced out the TreeItems are vertically
   */
  verticalSpacing?: string | number;
  /**
   * Whether to highlight the currently selected TreeItem
   */
  highlightSelected?: boolean;
}

/**
 * Renders a TreeView component
 */
export const TreeView: React.FC<React.PropsWithChildren<TreeViewProps>> = ({
  children,
  verticalSpacing,
  highlightSelected = true,
  ...props
}: TreeViewProps) => {
  return (
    <MuiTreeView
      {...props}
      sx={{
        '& > .MuiTreeItem-root': {
          mb: verticalSpacing,
          '& > .MuiTreeItem-content.Mui-selected': {
            backgroundColor: highlightSelected ? undefined : 'transparent',
            '&.Mui-focused': {
              backgroundColor: highlightSelected ? undefined : 'transparent',
            },
          },
        },
        ...props.sx,
      }}
    >
      {children}
    </MuiTreeView>
  );
};
