import React from 'react';
import { AlertColor, SnackbarCloseReason, SnackbarOrigin } from '@mui/material';
import { Alert, AlertTitle, Snackbar } from '../../index';

export interface ToastProps {
  /**
   * If true the toast will be open, meaning the contents will be visible
   */
  open: boolean;
  /**
   * Optional text to displayed as an AlertTitle
   */
  title?: string;
  /**
   * The alert child contents
   */
  children?: React.ReactNode;
  /**
   * The severity of the alert determines the colors used
   */
  severity: AlertColor;
  /**
   * Optional anchorOrigin override defaults to vertical = top and horizontal = right
   */
  anchorOrigin?: SnackbarOrigin;
  /**
   * Function to call when toast is closed
   */
  onClose: (event: Event | React.SyntheticEvent<unknown, Event>, reason: SnackbarCloseReason | 'clickedClose') => void;
  /**
   * Optional duration, indicates how long toast will be displayed for and defaults to 6000ms
   */
  autoHideDuration?: null | number;
  /**
   * Optional data test id
   */
  'data-testid'?: string;
}

/** Renders a Toast component */
export const Toast: React.FC<React.PropsWithChildren<ToastProps>> = ({
  autoHideDuration = 6000,
  children,
  'data-testid': testId = 'toast-snackbar',
  onClose,
  open,
  severity,
  title,
  anchorOrigin = {
    vertical: 'top',
    horizontal: 'right',
  },
}: ToastProps) => {
  return (
    <Snackbar
      open={open}
      anchorOrigin={anchorOrigin}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
      data-testid={testId}
    >
      <Alert severity={severity} onClose={(e) => onClose(e, 'clickedClose')}>
        {title ? <AlertTitle>{title}</AlertTitle> : null}
        {children}
      </Alert>
    </Snackbar>
  );
};
