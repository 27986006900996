import React, { useEffect, useState } from 'react';
import { Box, Drawer, IconButton, Typography } from '@mui/material';
import { Button, Icon, IconSize, MobileNav } from '../../index';

export interface ListSideBarProps {
  isSmallDevice: boolean;
  t: (key: string) => string;
  setIsSearchEnabled: (enabled: boolean) => void;
  restoreInitialData?: (data: any) => void;
  initialData?: any;
  children: React.ReactNode;
  drawerMode?: {
    show: boolean;
    onClose: () => void;
  };
}

/**
 * Generic list side bar component
 * @param isSmallDevice - small device or not
 * @param t - translation fn
 * @param setIsSearchEnabled - function that pauses searches while a filter selection is made
 * @param initialData - the filter state when the component is opened
 * @param restoreInitialData - a function for restoring the data to the state it was before opening
 * @param children - filter & sort list
 * @param drawerMode - is used for linked list pages, if this is defined then for non-small viewports the side bar is a
 * right sided drawer. The filter button also has different styles.
 *
 * @returns
 */
const ListSideBar: React.FC<React.PropsWithChildren<ListSideBarProps>> = ({
  isSmallDevice,
  t,
  setIsSearchEnabled,
  restoreInitialData,
  initialData,
  children,
  drawerMode = undefined,
}: ListSideBarProps) => {
  const [show, setShow] = useState(false);
  const [initialDataSnapshot, setInitialDataSnapshot] = useState();
  const isDrawerMode = drawerMode !== undefined ? true : false;
  const isOverlay = isSmallDevice || isDrawerMode;

  useEffect(() => {
    if (isDrawerMode) setShow(drawerMode.show);
  }, [drawerMode?.show]);

  useEffect(() => {
    if (show) {
      // to avoid showing two scrollbars
      document.documentElement.style.overflowY = 'hidden';
      setIsSearchEnabled(false);
      setInitialDataSnapshot(initialData);
    } else {
      document.documentElement.style.overflowY = 'scroll';
      setIsSearchEnabled(true);
    }
  }, [show]);

  if (isSmallDevice && !show) {
    return <MobileNav filterOnClick={() => setShow(true)} t={t} variant={isDrawerMode ? 'linkedPage' : 'listPage'} />;
  }

  const hideSideBar = () => {
    setShow(false);
    if (isDrawerMode) drawerMode.onClose();
  };

  const handleCloseButtonClick = () => {
    if (restoreInitialData) {
      restoreInitialData(initialDataSnapshot);
      setInitialDataSnapshot(undefined);
    }

    hideSideBar();
  };

  const Content = (
    <>
      {isOverlay && (
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <IconButton
              onClick={handleCloseButtonClick}
              sx={{ px: 0, mr: 6 }}
              aria-label={t('ns.search_filters:mobile:close_button')}
              data-testid="close-sidebar"
            >
              <Icon size={IconSize.SM} folder="actions/01-close" />
            </IconButton>

            <Typography component="h1" variant="h6">
              {t('ns.search_filters:mobile:title')}
            </Typography>
          </Box>

          <Box display="flex" alignItems="center">
            <Button variant="contained" sx={{ mx: 'auto' }} onClick={hideSideBar} data-testid="apply-filters-button">
              {t('ns.search_filters:mobile:apply_button')}
            </Button>
          </Box>
        </Box>
      )}

      {children}
    </>
  );

  if (isDrawerMode && !isSmallDevice)
    return (
      <Drawer
        anchor="right"
        open={show}
        onClose={handleCloseButtonClick}
        sx={{ '& .MuiDrawer-paper': { width: 375 } }}
        elevation={0}
      >
        <Box sx={{ paddingTop: 8, paddingX: 6 }}>{Content}</Box>
      </Drawer>
    );

  return (
    <Box
      sx={
        isSmallDevice
          ? {
              position: 'fixed',
              top: 0,
              bottom: 0,
              width: '100%',
              background: '#fff',
              zIndex: 999,
              overflowY: 'auto',
              paddingX: 4,
              pt: 4,
            }
          : {
              paddingTop: 8,
              paddingX: 6,
            }
      }
      pb={4}
    >
      {Content}
    </Box>
  );
};

export { ListSideBar };
