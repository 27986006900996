import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { TenantConfig } from '@askporter/grieg-types';
import { FatalError } from './types';
import { defaultConfig, remediateErrors, validateConfig } from './utils';

export interface ConfigProviderProps {
  /**
   * Child components to be rendered when not loading and not errored (also see continueOnStatusCodes)
   */
  children: React.ReactNode[] | React.ReactNode;
  /**
   * Component to render on error
   */
  errorPageComponent: React.ReactNode[] | React.ReactNode;
  /**
   * Component to render when loading
   */
  loadingComponent: React.ReactNode[] | React.ReactNode;
  /**
   * The config provider will default to displaying the errorPageComponent if calling the static/tenant endpoint fails,
   * providing a specific error code and component will render that component when the code is hit instead
   */
  codeSpecificErrorPages?: { code: number; component: React.ReactNode[] | React.ReactNode }[];
}

// Assign a set of initial values for the context. Once the config data has been fetched with React query we validate it
// and assign some more meaningful (usable) default/fallback values if anything is missing
export const ConfigContext = React.createContext<TenantConfig>({
  tenantName: '',
  tenantDisplay: {
    palette: defaultConfig.tenantDisplay.palette,
    links: {
      privacyPolicyUrl: '',
      termsOfUseUrl: '',
      landingZonePath: '',
    },
    logos: {
      logo: '',
      logoSmall: '',
      favicon: '',
    },
    coverImages: [],
  },
  tenantContact: {
    phoneNumber: '',
    email: '',
  },
  tenantBot: {
    botName: '',
    principalConversationTypeUrn: '',
    avatar: '',
  },
  tenantCognito: {
    userPool: '',
    appClientId: '',
    issuer: '',
  },
  tenantIntegrations: {
    analyticsId: 27,
    mapsKey: '',
  },
  featureFlags: {},
  tenantLanguages: [],
});

/**
 * Hook for obtaining the tenant data from the Config context
 *
 * @returns The config object
 */
export const useConfig = (): TenantConfig => {
  const context = useContext(ConfigContext);
  if (context === undefined) {
    throw new Error('useConfig hook must be used within an ConfigProvider component');
  }
  return context;
};

/**
 * Provider component which passes down the config/tenant data
 *
 * @param children - The React children components to wrap with the provider
 * @returns ConfigProvider component with children
 */
export const ConfigProvider: React.FC<React.PropsWithChildren<ConfigProviderProps>> = ({
  children,
  errorPageComponent,
  loadingComponent,
  codeSpecificErrorPages = [],
}: ConfigProviderProps) => {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [responseCode, setResponseCode] = useState<number>();

  useEffect(() => {
    let ignore = false;
    let retry = 0;
    const maxRetries = 2;

    const fetchTenant = async () => {
      try {
        const { data, status } = await axios.get('/api/v1/static/tenant');
        setResponseCode(status);
        if (!ignore) {
          setData(data);
          setIsLoading(false);
        }
      } catch (error) {
        setResponseCode(error?.response?.status);
        if (!ignore) {
          if (retry < maxRetries) {
            retry += 1;
            setTimeout(async () => await fetchTenant(), 1000 * retry);
          } else {
            setIsLoading(false);
          }
        }
      }
    };

    fetchTenant();

    return () => {
      ignore = true;
    };
  }, []);

  const [isDataValidated, setIsDataValidated] = useState<boolean>(false);
  const [fatalError, setFatalError] = useState<FatalError>();
  const [validatedData, setValidatedData] = useState<TenantConfig>({ ...(data as TenantConfig) });

  // Once the config data has been retrieved, validate it, resolve and report any errors.
  useEffect(() => {
    if (!isLoading) {
      const configData = { ...(data as TenantConfig) };
      const { configErrors, fatalError: fatalErr } = validateConfig(configData);
      if (fatalErr) setFatalError(fatalErr);

      if (Object.keys(configErrors).length > 0) {
        remediateErrors(configErrors, configData);
      }

      if (fatalError === undefined) setIsDataValidated(true);

      setValidatedData((prevValues) => {
        return { ...prevValues, ...configData };
      });
    }
  }, [isLoading]);

  // If we hit a fatal error, we render a minimal version of the app withe a fallback theme, without this we would need
  // to consider including various providers (such as )
  if (fatalError && !isLoading) {
    const errorComponent =
      codeSpecificErrorPages.find(({ code }) => code === responseCode)?.component || errorPageComponent;

    return <ConfigContext.Provider value={validatedData}>{errorComponent}</ConfigContext.Provider>;
  }
  // display a loader while we validate and fetch config
  else if (!isDataValidated || isLoading) return <>{loadingComponent}</>;
  else return <ConfigContext.Provider value={validatedData}>{children}</ConfigContext.Provider>;
};
