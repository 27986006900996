import React, { ChangeEvent } from 'react';
import { Box, FormControl, FormControlLabel, RadioGroup, Radio, Typography } from '@mui/material';

interface SearchSortProps {
  t: (key: string) => string;
  items: Array<{ label: string; value: string }>;
  onChange: (value: string) => void;
  value: string;
}

const SearchSort = ({ t, items, onChange, value }: SearchSortProps): JSX.Element => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };
  return (
    <Box sx={{ mb: 4 }}>
      <FormControl component="fieldset" sx={{ m: 0 }}>
        <Typography component="legend" variant="h6">
          {t('ns.common:sort_by_label')}
        </Typography>
        <RadioGroup aria-label={t('ns.common:sort_by_label')} name="sort-options" value={value} onChange={handleChange}>
          {items.map(({ label, value }) => {
            return (
              <FormControlLabel
                key={value}
                value={value}
                control={<Radio />}
                label={label}
                data-testid={`sort-by-option--${value}`}
              />
            );
          })}
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

export { SearchSort };
