export * from './utils/callRecords';
export { cleanEmail } from './utils/cleanEmail';
export { copyToClipboard } from './utils/copyToClipboard';
export { formatDate } from './utils/formatDate';
export * from './utils/formatTimestamp';
export { getInitials, getInitialsFromLongName } from './utils/getInitials';
export { fullName } from './utils/fullName';
export { generateAssetTitle } from './utils/generateAssetTitle';
export { genericFormatter } from './utils/genericFormatter';
export { validEmailRegex } from './utils/validEmail';
export { groupTaskTypes } from './utils/groupTaskTypes';
export { noteEntityConfig } from './utils/noteEntityConfig';
export { paginateArray } from './utils/paginateArray';
export { convertHexToRGBA } from './utils/convertHexToRGBA';
export * from './utils/toggleValueInList';
export { getTimeFromIsoDate } from './utils/getTimeFromIsoDate';
export { getUserFriendlyError } from './utils/errorMapper';
export { formatNumber } from './utils/formatNumber';
export { useDebounce } from './utils/useDebounce';
export { isAnonymousUser } from './utils/isAnonymousUser';
export { permissionMapping } from './utils/permissionMapping';
export * from './types';
export {
  getRequiredFields,
  convertValidationRules,
  convertExtMetadataToSchema,
  getDynamicProperties,
  getDynamicUISchema,
  generateUISchema,
  generateJSONSchema,
  resolveRoles,
  resolveExtMetadataRolePolicy,
  resolveExtMetadataConfig,
  resolveExtendedMetadata,
  canOnlyReadExtMetadata,
  resolveActivities,
  transformExtendedMetadata,
} from './utils/metadataToSchema';
export { getAllowedRolesByRolePolicy } from './utils/rolePolicyMapping';
export { checkPermissions } from './utils/checkPermissions';
export { formatMediaItems, formatMediaItem } from './utils/formatMedia';
export { getFileDetails } from './utils/getFileDetails';
export { imageArray } from './utils/imageArray';
export { transformUploadData } from './utils/transformFileUpload';
export { convertAllUndefinedPropsToNull } from './utils/convertAllUndefinedPropsToNull';
export * from './utils/mergeArraysWithObjects';
export * from './utils/extendedMetadataFilters';
export * from './utils/generateTaskSearchFilters';
export { moveElementInArray } from './utils/arrayUtils';
export { removeDuplicates } from './utils/removeDuplicates';
export * from './utils/linkItems';
export * from './utils/relationshipDelete';
export * from './utils/generateDropdownList';
export * from './utils/locationDisplayName';
export * from './utils/uploadFiles';
export * from './utils/useFileUpload';
export { roleOptions, superRoleOptions } from './utils/roleOptions';
export * from './utils/ipAddressLocation';
export * from './utils/getCountryCodeFromLanguageCode';
export * from './utils/passwordRules';
export { phoneNumberIsValid } from './utils/phoneNumberIsValid';
export { convertCurrencyStringToNumber } from './utils/convertCurrencyStringToNumber';
export { slaIcons } from './utils/slaIconMapping';
export * from './utils/getCreateUnregisteredUserDefaultValues';
// TODO: Remove this temporary fix once the issue is resolved(most likely when mui packages get updated to the latest version)
export const TemporaryRequiredPropsFix = {} as {
  placeholder: unknown;
  onPointerEnterCapture: unknown;
  onPointerLeaveCapture: unknown;
};
