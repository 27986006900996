import React from 'react';
import { TreeItem as MuiTreeItem, TreeItemProps as MuiTreeItemProps } from '@mui/lab';
import { Box, SxProps, Typography, TypographyVariant } from '@mui/material';
import { Button, ButtonProps } from '../Buttons/Button';

export interface TreeItemProps extends Omit<MuiTreeItemProps, 'label' | 'sx'> {
  /**
   * The adornment to display before the tree label text
   */
  startAdornment?: JSX.Element | JSX.Element[];
  /**
   * Under certain conditions the startAdornment will require styling, for example to provide width and control overflow
   */
  startAdornmentWrapperSx?: SxProps;
  /**
   * The text to display in the tree item
   */
  labelText?: string;
  /**
   * The end adornment to display after the tree label text, this will be floated to the right
   */
  endAdornment?: JSX.Element | JSX.Element[];
  /**
   * Button props to render a button much like the endAdornment - it will be floated to the right
   */
  endButton?: ButtonProps;
  /**
   * The typography variant
   */
  variant?: TypographyVariant;
  /**
   * Whether the TreeItem appears nested or not
   */
  offset?: boolean;
}

/**
 * Renders a TreeItem component
 */
export const TreeItem: React.FC<React.PropsWithChildren<TreeItemProps>> = ({
  startAdornment,
  startAdornmentWrapperSx,
  labelText,
  endAdornment,
  endButton,
  color,
  variant,
  children,
  offset = true,
  ...props
}: TreeItemProps) => {
  return (
    <MuiTreeItem
      label={
        <Box sx={{ display: 'flex', alignItems: 'center', minHeight: '48px' }}>
          {!!startAdornment && <Box sx={{ mr: 2, ...startAdornmentWrapperSx }}>{startAdornment}</Box>}
          <Typography variant={variant} color={color}>
            {labelText}
          </Typography>
          {!!endAdornment && <Box sx={{ ml: 'auto' }}>{endAdornment}</Box>}
          {!!endButton && (
            <Box sx={{ ml: 'auto' }}>
              <Button
                {...endButton}
                onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                  event.stopPropagation();
                  endButton.onClick(event);
                }}
              />
            </Box>
          )}
        </Box>
      }
      sx={{
        '& > .MuiTreeItem-group': { ml: !offset ? 0 : undefined },
        '& .MuiTreeItem-content': {
          p: 0,
          '& > .MuiTreeItem-iconContainer': {
            // remove the right margin of the icon container to ensure the icons are aligned to labels, for example
            // when one child node is expandable and another child is not
            mr: 0,
          },
        },
        // '&:hover': { backgroundColor: '' }, // TODO apply correct colour when token/approach is finalised
      }}
      {...props}
      disabled={props.disabled}
    >
      {children}
    </MuiTreeItem>
  );
};
