import { DateTimeReadOnly } from './DateTimeReadOnly';
import { LangCode } from './LangCode';
import { SearchOrgFilter } from './SearchOrgFilter';
import { SearchOrgSort } from './SearchOrgSort';
import { TimezoneName } from './TimezoneName';
import { UIDReadOnly } from './UIDReadOnly';
import { URNReadOnly } from './URNReadOnly';
import { UserRefHydrated } from './UserRefHydrated';

export interface OrgExportReadExternal {
  uid: UIDReadOnly;
  urn: URNReadOnly;
  name: string;
  languageCode: LangCode;
  timezone: TimezoneName;
  resultCount?: number;
  progress: any;
  requestedAt: DateTimeReadOnly;
  createdBy: UserRefHydrated;
  type: OrgExportReadExternal_TypeEnum;
  fields: Array<OrgExportReadExternal_FieldsEnum>;
  filter: SearchOrgFilter;
  sort: SearchOrgSort;
}

export type OrgExportReadExternal_TypeEnum = 'ORG';

export const OrgExportReadExternal_TypeEnum = {
  ORG: 'ORG' as OrgExportReadExternal_TypeEnum,
};

export type OrgExportReadExternal_FieldsEnum = 'REGISTRATION_TOKEN';

export const OrgExportReadExternal_FieldsEnum = {
  REGISTRATIONTOKEN: 'REGISTRATION_TOKEN' as OrgExportReadExternal_FieldsEnum,
};
