import React, { useState } from 'react';
import {
  Box,
  ButtonBase,
  CardContent,
  IconButton,
  Skeleton,
  Tooltip,
  Typography,
  useTheme,
  LinearProgress,
  useMediaQuery,
  Theme,
} from '@mui/material';
import {
  TaskStatusSummaryExternal,
  TaskTypeSummaryExternal,
  TaskStatusType,
  TaskSearchResultExternalSla,
  AssetSummaryExternal,
  UserSummaryExternal,
  TaskPriorityReadExternal,
} from '@askporter/grieg-types';
import { copyToClipboard, formatDate } from '@askporter/utils';
import { Avatar, AvatarSize } from '../../Avatar';
import { Breadcrumb } from '../../Breadcrumb';
import { calculateBreadcrumbProps } from '../../Breadcrumb/utils/calculateBreadcrumbProps';
import { Breadcrumbs } from '../../Breadcrumbs';
import { Chip } from '../../Chip';
import Icon, { IconSize } from '../../Icon';
import { TaskCardPersonAvatar } from '../TaskCardPersonAvatar';
import { TaskCardSlaDisplay } from '../TaskCardSlaDisplay';

export interface TaskCardContentsProps {
  taskType?: TaskTypeSummaryExternal;
  title?: string;
  taskRef?: string;
  status?: TaskStatusSummaryExternal;
  sla?: TaskSearchResultExternalSla;
  closedAt?: string;
  principalAsset?: AssetSummaryExternal;
  assignedUser?: UserSummaryExternal;
  priority?: TaskPriorityReadExternal;
  initiator?: UserSummaryExternal;
  openedAt?: string;
  permissions?: Record<string, boolean>;
  value?: string;
  onClick?: () => void;
  isLoading?: boolean;
  t?: (key: string) => string;
  dynamicBreadcrumbsSize?: boolean;
  captureException?: (error: Error) => void;
  slaFilter?: 'RESPONSE' | 'RESOLUTION';
}

export interface TooltipState {
  visible: boolean;
  message: string;
}

export const TaskCardContents: React.FC<React.PropsWithChildren<TaskCardContentsProps>> = ({
  taskType,
  title,
  taskRef,
  status,
  sla,
  principalAsset,
  assignedUser,
  priority,
  initiator,
  openedAt,
  permissions: { isManager = false, isSuperServiceUser = false } = {},
  value,
  onClick,
  isLoading,
  t,
  dynamicBreadcrumbsSize,
  slaFilter,
  // eslint-disable-next-line no-console
  captureException = console.error,
}: TaskCardContentsProps): JSX.Element => {
  const {
    palette: {
      text: { secondary: secondaryTextColor },
      common: { white: commonWhite },
      grey,
    },
  } = useTheme();

  const [tooltipState, setTooltipState] = useState<TooltipState>({ visible: false, message: '' });

  const handleTooltipClose = () => {
    setTooltipState({ visible: false, message: '' });
  };

  const handleTooltipOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();

    const message = copyIconButton.onClick() || '';
    setTooltipState({ visible: true, message });
  };

  const copyFunction = () => {
    const isSuccess = copyToClipboard(`${window.location.origin}/app/tasks/${value}`, captureException);
    const message = isSuccess
      ? t('ns.task_list:task_link:clipboard_success')
      : t('ns.task_list:task_link:clipboard_error');

    return message;
  };

  const copyIconButton = isManager && !isLoading ? { position: 'overline', onClick: copyFunction } : undefined;
  const taskTypeIconColour = status?.statusType === TaskStatusType.CLOSED ? grey[900] : taskType?.icon?.iconColourMask;
  const priorityChipColour = status?.statusType === TaskStatusType.CLOSED ? grey[900] : priority?.icon?.iconColourMask;

  const openedAtDate = openedAt ? formatDate(openedAt, 'dd MMM yyyy') : undefined;

  let acceptableCharLength = 20;
  useMediaQuery((theme: Theme) => theme.breakpoints.up('xs')) ? (acceptableCharLength = 40) : null;
  useMediaQuery((theme: Theme) => theme.breakpoints.up('md')) ? (acceptableCharLength = 100) : null;
  useMediaQuery((theme: Theme) => theme.breakpoints.up('lg')) ? (acceptableCharLength = 200) : null;

  const breadcrumbs = [
    taskType?.taskTypeGroup?.displayName && <Breadcrumb key="first" text={taskType?.taskTypeGroup?.displayName} />,
    taskType?.displayName && <Breadcrumb key="second" text={taskType?.displayName} />,
    <Breadcrumb key="third" text={taskRef} />,
  ].filter(Boolean);

  return (
    <Box
      sx={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: '100%',
        height: '100%',
      }}
      onClick={onClick}
    >
      <Box data-testid="highlight">
        <Box
          sx={{
            flex: 1,
            justifyContent: 'space-between',
            mb: 3,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
        >
          <Box display="flex" alignItems={'center'}>
            <ButtonBase
              data-testid="base-card-breadcrumbs"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
              }}
            >
              <Breadcrumbs
                maxItems={dynamicBreadcrumbsSize ? undefined : 2}
                sx={{ alignSelf: 'center' }}
                variant="body2"
                color={secondaryTextColor}
                {...(dynamicBreadcrumbsSize ? calculateBreadcrumbProps({ breadcrumbs, acceptableCharLength }) : {})}
              >
                {breadcrumbs}
              </Breadcrumbs>
            </ButtonBase>
            {copyIconButton?.position === 'overline' && (
              <Tooltip
                disableFocusListener
                disableHoverListener
                disableTouchListener
                placement="right"
                arrow
                PopperProps={{
                  disablePortal: true,
                }}
                open={tooltipState.visible}
                title={tooltipState.message}
                data-testid="copy-overline-tooltip"
              >
                <IconButton
                  sx={{ ml: 1 }}
                  size="small"
                  data-testid="copy-overline-button"
                  onClick={(e) => {
                    handleTooltipOpen(e);
                    setTimeout(handleTooltipClose, 1500);
                  }}
                >
                  <Icon size={IconSize.SM} folder="actions/05-copy" fillColor={secondaryTextColor} />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </Box>
        <Box display="flex">
          <Box mr={4}>
            <Avatar
              dataTestId={`${value}-task-card-header-avatar`}
              altText={title}
              isLoading={isLoading}
              avatar={{
                mode: 'icon',
                variant: 'rounded',
                imagePath: taskType?.icon?.iconPath,
                colorMask: taskTypeIconColour,
                bgcolor: taskTypeIconColour ? commonWhite : undefined, // where a colour mask is applied we want a white bg
                fallback: <></>,
              }}
            />
          </Box>
          <Box sx={{ flex: 1, textOverflow: 'ellipsis', overflow: 'hidden' }}>
            <Typography noWrap variant="h6" data-testid={`${value}-task-card-header-title`}>
              {isLoading ? <Skeleton variant="text" animation="wave" width={AvatarSize.XL * 2} /> : title}
            </Typography>
            {isLoading ? (
              <Skeleton variant="text" animation="wave" width={AvatarSize.XL * 1.5} />
            ) : (
              !!principalAsset && (
                <Chip
                  data-testid={`${value}-task-card-header-subtitle`}
                  label={principalAsset ? principalAsset.title : isLoading ? '-' : ''}
                  variant="filled"
                  icon={
                    principalAsset?.icon?.iconPath && (
                      <Icon size={IconSize.SM} fullPath={principalAsset.icon.iconPath} fillColor={secondaryTextColor} />
                    )
                  }
                />
              )
            )}
          </Box>
        </Box>
        <CardContent sx={{ px: 0, '&:last-child': { pb: 0 } }}>
          {isLoading ? null : (
            <>
              <Box mb={4}>
                <Box display="flex" flexWrap="nowrap" alignItems="flex-end" justifyContent={'space-between'} mb={2}>
                  <Typography
                    variant="body2"
                    data-testid="task-card-status"
                    color="textSecondary"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                  >
                    {status?.displayName ? status?.displayName : ''}
                  </Typography>
                  <Typography
                    component="label"
                    variant="body2"
                    color="textSecondary"
                    id="task-progress-percentage-value"
                    data-testid="progress-percentage-value"
                  >
                    {status?.progressIndicator >= 0 ? `${status.progressIndicator}%` : ''}
                  </Typography>
                </Box>
                {status?.progressIndicator ? (
                  <LinearProgress
                    data-testid="progress-bar"
                    aria-labelledby="task-progress-percentage-value"
                    variant="determinate"
                    value={status?.progressIndicator}
                    sx={{ backgroundColor: 'background.default', borderRadius: 2 }}
                  />
                ) : null}
              </Box>
              <Box
                display="flex"
                flex={1}
                flexWrap="nowrap"
                alignItems="center"
                justifyContent="space-between"
                minHeight="24px"
              >
                <Box display="flex" flex={1} flexWrap="nowrap" alignItems="center">
                  <TaskCardPersonAvatar t={t} userType="assignee" user={assignedUser} isManager />
                  <Box display="flex" flexWrap="wrap" alignItems="center" gap={2}>
                    {(isManager || isSuperServiceUser) && priority ? (
                      <Chip
                        label={priority?.displayName}
                        icon={
                          <Icon
                            fullPath={priority?.icon?.iconPath}
                            size={IconSize.SM}
                            {...(priorityChipColour && { fillColor: commonWhite })}
                          />
                        }
                        sx={{
                          backgroundColor: priorityChipColour ? priorityChipColour : 'grey.300',
                          ...(priorityChipColour && {
                            '& .MuiChip-label': { color: commonWhite },
                          }),
                        }}
                      />
                    ) : null}
                    <TaskCardSlaDisplay sla={sla} t={t} slaFilter={slaFilter} />
                    {status?.statusType === TaskStatusType.FUTURE && (
                      <Chip
                        data-testid="scheduled-chip"
                        color="success"
                        label={t('ns.task_list:task_card:future')}
                        icon={
                          <Icon
                            size={IconSize.SM}
                            folder={'status/02-task-details/01-task-status'}
                            fillColor={commonWhite}
                          />
                        }
                        sx={{ color: commonWhite }}
                      />
                    )}
                  </Box>
                </Box>
                <Box display="flex" flexDirection="row" alignSelf="center" alignItems="center">
                  <TaskCardPersonAvatar t={t} userType="initiator" user={initiator} isManager />
                  {openedAtDate && (
                    <Typography data-testid="opened-at-date" variant="body2" color="textSecondary">{`${t(
                      'ns.common:created',
                    )}, ${openedAtDate}`}</Typography>
                  )}
                </Box>
              </Box>
            </>
          )}
        </CardContent>
      </Box>
    </Box>
  );
};
