import { useMutation } from 'react-query';
import {
  AssetExportCreate,
  OrgExportCreate,
  TaskExportCreate,
  TeamExportCreate,
  UserExportCreate,
} from '@askporter/grieg-types';
import { API } from '../utils';

export const useExportMutations = () => {
  return {
    taskExportMutation: useMutation((data: TaskExportCreate) => API().post({ path: `exports/task`, payload: data })),
    assetExportMutation: useMutation((data: AssetExportCreate) => API().post({ path: `exports/asset`, payload: data })),
    userExportMutation: useMutation((data: UserExportCreate) => API().post({ path: `exports/user`, payload: data })),
    orgExportMutation: useMutation((data: OrgExportCreate) => API().post({ path: `exports/org`, payload: data })),
    teamExportMutation: useMutation((data: TeamExportCreate) => API().post({ path: `exports/team`, payload: data })),
  };
};
