import React, { useEffect, useReducer } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { DayOfWeek } from '@askporter/grieg-types';
import { createDateObjectFromTimeString } from '../utils';
import { RangeRow } from './components/RangeRow';
import { SelectDayTimeModal } from './components/SelectDayTimeModal';
import { DayTimeRangeType, DayTimeState } from './types';
import { defaultDays, parseDayTime, selectAllDays, sortDays, reducer, days, getAllDayTimes } from './utils';

export type { DayTimeRangeType };

export interface DayTimeRangeProps {
  /**
   * (optional) the title to render above the component
   */
  title?: string | React.ReactNode;
  /**
   * (optional) the data-testid string for the button
   */
  dataTestId?: string;
  /**
   * (optional) the existing value / data to render
   */
  value?: Array<DayTimeRangeType>;
  /**
   * the translation function
   */
  t: (key: string, options?: Record<string, string | number>) => string;
  /**
   * whether or not we are on a device with a small screen
   */
  isSmallDevice: boolean;
  /**
   * callback function when the 'Add' button is clicked
   */
  onAdd: (value: Array<DayTimeRangeType>) => void;
  /**
   * if disabled then buttons are not rendered, only the data is shown
   */
  disabled?: boolean;
  /**
   * (optional) additional content to be rendered below existing rows
   */
  additionalContent?: React.ReactNode | React.ReactNode[];
}

/**
 * Allows the user to set a time range for multiple week days
 */
export const DayTimeRange: React.FC<React.PropsWithChildren<DayTimeRangeProps>> = ({
  t,
  title,
  value,
  dataTestId,
  isSmallDevice,
  onAdd,
  disabled,
  additionalContent,
}: DayTimeRangeProps) => {
  const parsedDayTimes = parseDayTime(getAllDayTimes(value));

  const initialState: DayTimeState = {
    isVisible: false,
    selectedDays: defaultDays,
    dayTimes: parsedDayTimes,
    fromTimeValue: null,
    toTimeValue: null,
    noServiceCheckbox: false,
  };

  const [{ isVisible, selectedDays, dayTimes, fromTimeValue, toTimeValue, noServiceCheckbox }, dispatch] = useReducer(
    reducer,
    initialState,
  );

  useEffect(() => {
    const newParsedDayTimes = parseDayTime(getAllDayTimes(value));
    dispatch({ type: 'addDayTime', payload: newParsedDayTimes });
  }, [value]);

  return (
    <>
      <Box data-testid={dataTestId} sx={{ width: { xxs: '100%', md: '400px' } }}>
        {typeof title === 'string' ? (
          <Typography component="h4" variant="h6">
            {title}
          </Typography>
        ) : (
          title
        )}

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 6,
            my: 7.25,
          }}
        >
          {Object.keys(dayTimes || {})
            .sort(sortDays)
            .map((day: DayOfWeek) => (
              <RangeRow
                key={day}
                title={t(`common:day_of_week:${day}`)}
                rangeText={
                  dayTimes[day]?.fromTime && dayTimes[day]?.toTime
                    ? `${dayTimes[day]?.fromTime}-${dayTimes[day]?.toTime}`
                    : t('day_time:no_times_set')
                }
                disabled={disabled}
                editAriaLabel={t(`day_time:edit:${day}`)}
                editOnClick={() =>
                  dispatch({
                    type: 'selectDay',
                    payload: {
                      selectedDays: {
                        ...defaultDays,
                        [days[day]]: true,
                      },
                      fromTimeValue: createDateObjectFromTimeString(dayTimes[day].fromTime),
                      toTimeValue: createDateObjectFromTimeString(dayTimes[day].toTime),
                    },
                  })
                }
              />
            ))}
          {additionalContent}
        </Box>

        {!disabled && (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Button
              onClick={() =>
                dispatch({
                  type: 'selectAllDays',
                  payload: selectAllDays,
                })
              }
              variant="outlined"
            >
              {t('day_time:edit:all_hours')}
            </Button>
            <Button
              onClick={() =>
                dispatch({
                  type: 'selectMonFri',
                  payload: {
                    ...defaultDays,
                    0: true,
                    1: true,
                    2: true,
                    3: true,
                    4: true,
                  },
                })
              }
              variant="outlined"
            >
              {t('day_time:edit:mon_fri')}
            </Button>
            <Button
              onClick={() =>
                dispatch({
                  type: 'selectSatSun',
                  payload: {
                    ...defaultDays,
                    5: true,
                    6: true,
                  },
                })
              }
              variant="outlined"
            >
              {t('day_time:edit:sat_sun')}
            </Button>
          </Box>
        )}
      </Box>
      {!disabled && (
        <SelectDayTimeModal
          selectedDays={selectedDays}
          setSelectedDays={(days) => dispatch({ type: 'setSelectedDays', payload: days })}
          title={t('day_time:select_day_time:title')}
          t={t}
          isSmallDevice={isSmallDevice}
          isVisible={isVisible}
          setIsModalVisible={(isVisible: boolean) => dispatch({ type: 'setModalVisibility', payload: isVisible })}
          handleClose={() => dispatch({ type: 'setModalVisibility', payload: false })}
          onAdd={onAdd}
          dayTimes={dayTimes}
          addDayTime={(dayTimesPayload: Record<DayOfWeek, { fromTime: string; toTime: string }>) =>
            dispatch({ type: 'addDayTime', payload: dayTimesPayload })
          }
          dataTestId="select-day-time-range-modal-body"
          value={value}
          fromTimeValue={fromTimeValue}
          toTimeValue={toTimeValue}
          setFromTimeValue={(fromTimeValue: Date | null) =>
            dispatch({ type: 'setFromTimeValue', payload: fromTimeValue })
          }
          setToTimeValue={(toTimeValue: Date | null) => dispatch({ type: 'setToTimeValue', payload: toTimeValue })}
          noServiceCheckbox={noServiceCheckbox}
          setNoServiceCheckbox={(noServiceCheckbox: boolean) =>
            dispatch({ type: 'setNoServiceCheckbox', payload: noServiceCheckbox })
          }
        />
      )}
    </>
  );
};
