import React from 'react';
import { Box } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { Button, Typography, Dialog, TextField, LoadingButton } from '@askporter/component-library';
import {
  AssetExportCreate,
  OrgExportCreate,
  TaskExportCreate,
  TeamExportCreate,
  UserExportCreate,
} from '@askporter/grieg-types';
import { ExportModalFormTypes, ExportResourceNameFilterAndSortUnion } from '../../../types';
import { generateExportFileName } from '../../../utils/generateExportFileName';
import { ExportFields } from '../../ExportFields';
import { useExportMutations } from './useExportMutations';

export type ExportModalProps = {
  /**
   * Whether the dialog is open
   */
  isOpen: boolean;
  /**
   * callback to close the dialog
   */
  onClose: () => void;
  /**
   * Whether the device is small
   */
  isSmallDevice: boolean;
  /**
   * The translation function
   */
  t: (key: string, options?: Record<string, string | number>) => string;
  /**
   * cancel export callback
   */
  cancelExport: () => void;
  /**
   * The title of the dialog
   */
  title: string;
} & ExportResourceNameFilterAndSortUnion;

/**
 * The Export modal component that contains all the common logic for the export forms
 */
export const ExportModal = ({
  isOpen,
  onClose,
  isSmallDevice,
  t,
  cancelExport,
  title,
  resourceName,
  filter,
  sort,
}: ExportModalProps): JSX.Element => {
  const { handleSubmit, control } = useForm<ExportModalFormTypes>({
    defaultValues: {
      timezone: Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone,
    },
  });
  const { taskExportMutation, assetExportMutation, userExportMutation, orgExportMutation, teamExportMutation } =
    useExportMutations();

  const onSubmit = (data: ExportModalFormTypes) => {
    switch (resourceName) {
      case 'tasks':
        {
          const payload: TaskExportCreate = {
            ...(data as TaskExportCreate),
            filter,
            sort,
          };
          // POST /api/v1/exports/task
          taskExportMutation.mutate(payload, { onSettled: () => console.log('settled') });
        }
        break;
      case 'assets':
        {
          const payload: AssetExportCreate = {
            ...(data as AssetExportCreate),
            filter,
            sort,
          };
          // POST /api/v1/exports/asset
          assetExportMutation.mutate({ ...payload }, { onSettled: () => console.log('settled') });
        }
        break;
      case 'users':
        {
          const payload: UserExportCreate = {
            ...(data as UserExportCreate),
            filter,
            sort,
          };
          // POST /api/v1/exports/user
          userExportMutation.mutate({ ...payload }, { onSettled: () => console.log('settled') });
        }
        break;
      case 'orgs':
        {
          const payload: OrgExportCreate = {
            ...(data as OrgExportCreate),
            filter,
            sort,
          };
          // POST /api/v1/exports/org
          orgExportMutation.mutate({ ...payload }, { onSettled: () => console.log('settled') });
        }
        break;
      case 'teams': {
        const payload: TeamExportCreate = {
          ...(data as TeamExportCreate),
          filter,
          sort,
        };
        // POST /api/v1/exports/team
        teamExportMutation.mutate({ ...payload }, { onSettled: () => console.log('settled') });
      }
    }
  };

  const isLoading =
    taskExportMutation.isLoading ||
    assetExportMutation.isLoading ||
    userExportMutation.isLoading ||
    orgExportMutation.isLoading ||
    teamExportMutation.isLoading;

  return (
    <Dialog
      onClose={onClose}
      isOpen={isOpen}
      isSmallDevice={isSmallDevice}
      title={title}
      actions={
        <>
          <Button variant="outlined" onClick={cancelExport}>
            {t('common:export:export_modal:cancel_export_button')}
          </Button>
          <LoadingButton variant="contained" type="submit" form={`${resourceName}-export-form`} isLoading={isLoading}>
            {t('common:export:export_modal:start_export_button')}
          </LoadingButton>
        </>
      }
    >
      <form id={`${resourceName}-export-form`} onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="name"
          control={control}
          defaultValue={generateExportFileName(resourceName)}
          render={({ field }) => (
            <TextField
              variant="outlined"
              label={t('common:export:export_modal:export_name')}
              fullWidth
              inputProps={{ ...field }}
            />
          )}
        />
        <Typography variant="h6" sx={{ marginTop: 5 }}>
          {t('common:export:export_modal:fields_to_export')}
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <ExportFields t={t} resourceName={resourceName} control={control} />
        </Box>
      </form>
      <Typography sx={{ marginTop: 5 }}>{t('common:export:export_modal:export_info')}</Typography>
    </Dialog>
  );
};
