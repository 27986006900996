import React, { ElementType } from 'react';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import {
  Card as MuiCard,
  CardProps as MuiCardProps,
  CardActionArea,
  Box,
  Checkbox,
  Radio,
  AccordionSummary,
  Typography,
  Divider,
  AccordionDetails,
  useTheme,
} from '@mui/material';
import { Accordion } from '../Accordion';
import { IconButton } from '../Buttons/IconButton';
import Icon, { IconSize } from '../Icon';

interface CardProps extends MuiCardProps {
  /**
   * (optional) the data-testid string for the button
   */
  dataTestId?: string;
  /**
   * (optional) boolean that will enable/disable card hover/pressed state change
   */
  readOnly?: boolean;
  /**
   * (optional) card mode (defaults to standard): 'standard' | 'checkbox' | 'radio' | 'action'
   */
  cardMode?: 'standard' | 'checkbox' | 'radio' | 'action';
  /**
   * (optional) callback for when the selection checkbox/radio changes when in checkbox/radio cardMode
   */
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  /**
   * (optional) boolean for whether the card is selected or not (defaults to false)
   */
  isSelected?: boolean;
  /**
   * (optional) the value for the input if the 'cardMode' is 'checkbox' or 'radio'
   */
  value?: string | number;
  /**
   * (optional) width of the card
   */
  width?: string | number;
  /**
   * (optional) borderRadius for the card
   */
  borderRadius?: string | number;
  /**
   * (optional) plain === true removes styling for the card
   */
  plain?: boolean;
  /**
   * (optional) the 'onClick' handler when the card is clicked (if not 'readOnly' or 'standard')
   */
  onClick?: () => void;
  /**
   * (optional) options to render a close button if this is passed in
   */
  closeButton?: { onClose: () => void; ariaLabel?: string };
  /**
   * (optional) render an expandable section at the bottom of the card
   */
  expandableSection?: ExpanableSectionProps;
  /**
   * (optional) the action area aria label
   */
  actionAreaAriaLabel?: string;
  /**
   * (optional) the component to render the card as
   */
  component?: ElementType<unknown>;
}

export interface ExpanableSectionProps {
  /**
   * the display text for the button that opens/closes the expandable section
   */
  title: string;
  /**
   * the body to show when the expandable section is open
   */
  body: React.ReactNode;
}

/**
 * Card in ASK component library style
 */
export const Card: React.FC<React.PropsWithChildren<CardProps>> = ({
  children,
  readOnly = false,
  dataTestId = 'ask-card',
  cardMode = 'standard',
  onChange,
  isSelected = false,
  value,
  width,
  borderRadius = 2,
  plain = false,
  onClick,
  expandableSection,
  closeButton,
  actionAreaAriaLabel,
  sx = {},
  ...cardProps
}: CardProps): JSX.Element => {
  const {
    palette: {
      primary: { light, main },
    },
  } = useTheme();

  return (
    <MuiCard
      data-testid={dataTestId}
      variant="outlined"
      sx={{
        ...sx,
        width: width,
        ...(plain && {
          border: 'none',
          backgroundColor: 'transparent',
        }),
        borderRadius: borderRadius,
        ...(!(plain || readOnly) &&
          cardMode === 'action' && {
            borderLeft: 'none',
          }),
        ...(isSelected && {
          border: 2,
          borderColor: light,
          backgroundColor: `${main.substring(0, 7)}14`,
        }),
        ...((plain || readOnly) && {
          p: 4,
        }),
      }}
      {...cardProps}
    >
      {(plain || readOnly) && (cardMode === 'standard' || cardMode === 'action') ? (
        <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', width: '100%' }}>
          <Box sx={{ flex: 1, width: '100%' }}>{children}</Box>
          {closeButton && (
            <Box>
              <IconButton onClick={closeButton.onClose} aria-label={closeButton.ariaLabel}>
                <Icon size={IconSize.SM} folder="actions/01-close" />
              </IconButton>
            </Box>
          )}
        </Box>
      ) : (
        <CardActionArea
          component="div"
          role="button"
          onClick={onClick}
          aria-label={actionAreaAriaLabel || 'card action area'}
          sx={{ p: 4 }}
        >
          <Box display="flex">
            {!(plain || readOnly) && cardMode === 'action' && (
              <Box
                data-testid="card-action-overlay"
                sx={{
                  width: '4px',
                  backgroundColor: main,
                  height: '100%',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  zIndex: 10,
                }}
              />
            )}
            {cardMode === 'radio' && (
              <Box sx={{ pr: 4, alignSelf: 'center' }}>
                <Radio
                  checked={isSelected}
                  aria-label={'card selection radio'}
                  onChange={onChange}
                  value={value}
                  sx={{ '&: hover': { backgroundColor: 'transparent' } }}
                />
              </Box>
            )}
            {cardMode === 'checkbox' && (
              <Box sx={{ pr: 4, alignSelf: 'center' }}>
                <Checkbox
                  checked={isSelected}
                  aria-label={'card selection checkbox'}
                  onChange={onChange}
                  value={value}
                  sx={{ '&: hover': { backgroundColor: 'transparent' } }}
                />
              </Box>
            )}
            <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', width: '100%' }}>
              <Box sx={{ flex: 1, width: '100%' }}>{children}</Box>
              {closeButton && (
                <Box>
                  <IconButton
                    onClick={(e?: React.MouseEvent) => {
                      e.stopPropagation();
                      closeButton.onClose();
                    }}
                    aria-label={closeButton.ariaLabel}
                  >
                    <Icon size={IconSize.SM} folder="actions/01-close" />
                  </IconButton>
                </Box>
              )}
            </Box>
          </Box>
        </CardActionArea>
      )}

      {expandableSection && (
        <Box sx={plain || readOnly ? { px: 0, mb: -4, mt: 4 } : { px: 4 }}>
          <Divider />
          <Accordion
            titleComponent={
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="body2">{expandableSection.title}</Typography>
              </AccordionSummary>
            }
            contentComponent={<AccordionDetails>{expandableSection.body}</AccordionDetails>}
            displayDivider={false}
          />
        </Box>
      )}
    </MuiCard>
  );
};
