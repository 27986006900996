import { TaskTypeGroupListExternal, TaskTypeGroupListExternalTaskTypeGroups } from '@askporter/grieg-types';
import { API } from './api';

export default async (): Promise<Array<TaskTypeGroupListExternalTaskTypeGroups>> => {
  const data: TaskTypeGroupListExternal = await API().get({ path: 'task-types' });

  if (Array.isArray(data?.taskTypeGroups) && data?.taskTypeGroups?.length >= 1) {
    // sort results alphabetically first by group display name and then by task type display name
    const sortedGroups = data?.taskTypeGroups?.sort((a, b) => a.displayName.localeCompare(b.displayName));
    sortedGroups.forEach((group) => group.taskTypes.sort((a, b) => a.displayName.localeCompare(b.displayName)));

    return sortedGroups;
  } else return [];
};
