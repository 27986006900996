export type LinkType =
  | 'TASK_LINK'
  | 'ASSET_LINK'
  | 'ON_SITE_LINK'
  | 'ASSET_REG_LINK'
  | 'ORG_REG_LINK'
  | 'TASK_MESSAGE_THREAD_LINK'
  | 'OUTBOUND_CONVERSATION'
  | 'FILE_LINK';

export const LinkType = {
  TASKLINK: 'TASK_LINK' as LinkType,
  ASSETLINK: 'ASSET_LINK' as LinkType,
  ONSITELINK: 'ON_SITE_LINK' as LinkType,
  ASSETREGLINK: 'ASSET_REG_LINK' as LinkType,
  ORGREGLINK: 'ORG_REG_LINK' as LinkType,
  TASKMESSAGETHREADLINK: 'TASK_MESSAGE_THREAD_LINK' as LinkType,
  OUTBOUNDCONVERSATION: 'OUTBOUND_CONVERSATION' as LinkType,
  FILELINK: 'FILE_LINK' as LinkType,
};
