import React from 'react';
import { Box } from '@mui/material';
import { Button } from '../../index';

export interface MobileNavProps {
  filterOnClick: (e: React.SyntheticEvent) => void;
  t: (key: string) => string;
  variant?: 'listPage' | 'linkedPage';
}

/**
 * Renders a MobileNav component when isSmallDevice is true
 * @param isSmallDevice - Whether we are in single / split column view
 * @param filterOnClick - Handler for the Filter button onClick method
 */
const MobileNav: React.FC<React.PropsWithChildren<MobileNavProps>> = ({
  filterOnClick,
  t,
  variant = 'listPage',
}: MobileNavProps) => {
  return (
    <Box position="fixed" bottom={20} display="flex" justifyContent="center" width="100%" zIndex={1}>
      {variant === 'listPage' && (
        <Button
          color="secondary"
          variant="contained"
          onClick={filterOnClick}
          data-testid="mobile-nav-filters-button"
          sx={{
            borderRadius: 22,
            boxShadow: 3,
            height: 48,
            width: 'inherit',
            mx: 4,
            backgroundColor: 'grey.50',
          }}
        >
          {t('ns.mobile_nav:refine_button')}
        </Button>
      )}
      {variant === 'linkedPage' && (
        <Button
          sx={{ ml: 2, width: 343, maxWidth: '90%', borderRadius: 22, backgroundColor: 'common.white', boxShadow: 3 }}
          onClick={filterOnClick}
          color="secondary"
          variant="contained"
          size="large"
          data-testid="mobile-nav-filters-button"
        >
          {t('ns.mobile_nav:filter_button')}
        </Button>
      )}
    </Box>
  );
};

export { MobileNav };
