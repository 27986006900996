import React from 'react';
import { Box, CssBaseline, Theme, useMediaQuery } from '@mui/material';
import { AskPorterGrid } from '../AskPorterGrid';
import { SideBarLayout, SplitPageLayout, SimplePageLayout } from './types';
import { generateHeights } from './utils/calcs';
import { isSideBarLayout, isSimplePageLayout, isSplitPageLayout } from './utils/detectLayoutType';
export * from './types';

type Navigation = {
  /**
   * The navigation component
   */
  navigation: React.ReactNode;
};

type CommonProps = {
  /**
   * Contents overflow setting
   */
  overflow?: 'auto' | 'none';
};

type FooterProps = {
  /**
   * The footer component
   */
  footer: React.ReactNode;
  /**
   * Accepts either a Boolean indicating whether the footer should be shown. Also accepts a string 'half' for
   * SplitPageLayout ONLY which indicates footer should be 50% of screen width.
   */
  showFooter: boolean | 'half';
};

type CommonWithFooterProps = CommonProps & FooterProps;
type CommonWithoutFooterProps = CommonProps & { footer?: never; showFooter?: never };

// use discriminate types to provide a final type per layout, this will help prevent forgotten headers etc.
type LayoutProps =
  | ({ layoutType: 'sidebarLayoutList' } & SideBarLayout & Navigation & CommonWithoutFooterProps)
  | ({ layoutType: 'sidebarLayoutDetails' } & SideBarLayout & Navigation & CommonWithoutFooterProps)
  | ({ layoutType: 'simplePageLayout' } & SimplePageLayout & Navigation & Partial<CommonWithFooterProps>)
  | ({ layoutType: 'splitPageLayout' } & SplitPageLayout & Partial<Navigation> & CommonWithFooterProps);

/**
 * Responsive Layout component which accepts components as params to produce a page with a navigation and a header
 * @returns Layout with responsive nav, footer (if specified) and supplied child component(s) as content
 */
export const CoreLayout: React.FC<React.PropsWithChildren<LayoutProps>> = ({
  navigation,
  footer,
  showFooter,
  overflow = 'none',
  // not using at the moment, but should replace the isLayout...Fns
  layoutType,
  ...layout
}: LayoutProps) => {
  const { footerHeight, navigationHeight, constrainedHeight } = generateHeights({
    showNavigation: !!navigation,
    showFooter,
  });
  const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'), { noSsr: true });
  const contentComponent = isSimplePageLayout(layout) ? 'main' : 'div';
  const shouldConstrainHeight = isSideBarLayout(layout) && layout?.constrainHeight;

  /**
   * Identifies and renders the correct content based on the layout provided
   */
  const renderContent = () => {
    if (isSideBarLayout(layout)) {
      const mobileMain = layout.mobileMain ? layout.mobileMain : 'main';

      return (
        <Box
          sx={{
            width: { xxs: '100%', sm: '100%', md: '100%' },
            display: 'flex',
            flexDirection: 'row',
            height: 'max(100%, 100vh)',
          }}
        >
          <Box
            component={smUp ? 'aside' : 'main'}
            sx={{
              width: { xxs: '100%', sm: '372px', md: '448px' },
              overflowY: layout?.constrainHeight ? 'auto' : undefined,
            }}
            bgcolor={!smUp && layout.flatMain ? '' : 'background.paper'}
          >
            <Box sx={{ height: layout?.constrainHeight ? '100%' : undefined }}>
              {smUp ? layout.aside : layout[mobileMain]}
            </Box>
          </Box>
          {smUp && (
            <Box
              component="main"
              p={6} // 24px(1.5rem) as per design
              borderLeft={1}
              borderColor="divider"
              sx={{
                backgroundColor: layoutType === 'sidebarLayoutDetails' ? 'white' : 'transparent',
                width: { xxs: '100%', sm: 'calc(100% - 320px)' },
              }}
            >
              {layout.main}
            </Box>
          )}
        </Box>
      );
    } else if (isSplitPageLayout(layout)) {
      const flexDirection = layout.flexDirection ? layout.flexDirection : 'row';

      return (
        <AskPorterGrid container item sm={12} minHeight="100%" flexDirection={flexDirection}>
          <AskPorterGrid item container component="main" xxs={12} sm={6} minHeight="100%">
            <Box width="100%" display="flex" flexDirection="column" flexWrap="nowrap" data-testid="half-footer-box">
              <Box flexGrow={1}>{layout.main}</Box>
              {/* If the footer is half then render it within this box so it inherits the parent width */}
              {showFooter === 'half' && renderFooter}
            </Box>
          </AskPorterGrid>
          {smUp && (
            <AskPorterGrid component="aside" item sm={6}>
              {layout.right}
            </AskPorterGrid>
          )}
        </AskPorterGrid>
      );
    } else if (isSimplePageLayout(layout)) return layout.main;

    // If the layout cannot be established return null to ensure nothing is rendered
    return null;
  };

  /**
   * Identifies and renders the correct footer
   */
  const renderFooter = (
    <AskPorterGrid item width="100%" height={footerHeight} data-testid="footer">
      {footer}
    </AskPorterGrid>
  );

  return (
    <React.Fragment>
      <CssBaseline />
      {/* The generic layout uses a grid flexed as cols, this stacks the header, content and footer. */}
      <AskPorterGrid flexDirection="column" flexWrap="nowrap" height="100%" container position="relative">
        {!!navigation && (
          <AskPorterGrid
            item
            borderBottom={1}
            borderColor="divider"
            minHeight={`${navigationHeight}px`}
            maxHeight={`${navigationHeight}px`}
            data-testid="navigation"
          >
            {navigation}
          </AskPorterGrid>
        )}
        <AskPorterGrid
          container
          item
          flexDirection="row"
          flexGrow={1}
          overflow={overflow}
          component={contentComponent}
          height={shouldConstrainHeight ? constrainedHeight : undefined}
        >
          {renderContent()}
        </AskPorterGrid>
        {/* Render a footer only when footer is true, half footer is handled in the SplitPageLayout */}
        {showFooter === true && renderFooter}
      </AskPorterGrid>
    </React.Fragment>
  );
};
