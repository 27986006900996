import React, { useReducer } from 'react';
import { ListItemAvatar } from '@mui/material';
import { useQuery } from 'react-query';
import { SelectListItem, AddLinkedItemForm, Icon, IconSize } from '@askporter/component-library';
import { SearchTaskResultExternal, TaskSearchResultExternal, SearchTaskRequest, URN } from '@askporter/grieg-types';
import { toggleValueInList } from '@askporter/utils';
import { API } from '../../api';
import linkTasksReducer, { initialState } from './reducer';
import { LinkTasksState } from './types';

interface LinkTasksProps {
  isVisible: boolean;
  setVisibility: (visibility: boolean) => void;
  onLink: (tasks: Array<URN>) => Promise<void>;
  t: (key: string, options?: Record<string, string | number>) => string;
  isSmallDevice: boolean;
  filter: Omit<SearchTaskRequest['filter'], 'freeText'>;
}

/**
 * Renders the link task form, includes API calls for task search
 *
 * @param isVisible - indicates visibility
 * @param setVisibility - function for toggling visibility
 * @param onLink - function to link tasks to an entity
 * @param isSmallDevice - small viewport or not
 * @param t - The translation function
 * @param filter - task search filter
 *
 */
export const LinkTasks: React.FC<React.PropsWithChildren<LinkTasksProps>> = ({
  isVisible,
  setVisibility,
  onLink,
  isSmallDevice,
  t,
  filter,
}: LinkTasksProps) => {
  const [{ search, selectedItems, isProcessing }, dispatch] = useReducer(linkTasksReducer, initialState(filter));

  // query to search
  const { data: taskSearchResult } = useQuery<{ data: SearchTaskResultExternal }>(['tasks/search', search], () =>
    API().post({ path: 'tasks/search', payload: search }),
  );

  // dispatch wrappers
  const setIsProcessing = (isProcessing: boolean) => dispatch({ type: 'SET_IS_PROCESSING', payload: isProcessing });
  const setSearchFreeText = (text: string) => dispatch({ type: 'SET_SEARCH_FREE_TEXT', payload: text });
  const setPage = (page: number) => dispatch({ type: 'SET_PAGE', payload: page });
  const setSelectedItems = (selection: LinkTasksState['selectedItems']) =>
    dispatch({ type: 'SET_SELECTED_ITEMS', payload: selection });
  const clearSelection = () => dispatch({ type: 'CLEAR_SELECTION' });

  // handlers
  const handleSelectAllOnChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    results: SearchTaskResultExternal['results'],
  ) => {
    !e.target.checked ? clearSelection() : setSelectedItems(results?.map((result) => result?.urn));
  };

  const handleSubmitOnClick = () => {
    setIsProcessing(true);

    // without a slight delay the component does not hit loading state until just before it completes, which
    // doesn't look great, so this provides a solution to that
    setTimeout(async () => {
      await onLink(selectedItems);
      setIsProcessing(false);
      setVisibility(false);
      clearSelection();
    }, 500);
  };

  return (
    <AddLinkedItemForm
      isVisible={isVisible}
      results={taskSearchResult?.data?.results}
      title={t('ns.linked_tasks:link_form:title')}
      searchButtonAriaLabel={t('ns.common:search:tasks')}
      handleClose={() => {
        setVisibility(false);
        clearSelection();
      }}
      submitButton={{
        onClick: handleSubmitOnClick,
        text: t(`ns.linked_tasks:link_form:select_tasks${selectedItems?.length > 0 ? '' : '_disabled'}`, {
          count: selectedItems?.length || 0,
        }),
        disabled: selectedItems?.length > 0 && !isProcessing ? false : true,
        loading: isProcessing,
      }}
      pagination={{
        page: search.page,
        pageCount: taskSearchResult?.data?.summary?.totalPages || 0,
        onChange: (_, page) => setPage(page),
      }}
      searchFreeText={search.freeText}
      setSearchFreeText={setSearchFreeText}
      isSmallDevice={isSmallDevice}
      t={t}
      selectAllCheckbox={{
        onChange: (e) => handleSelectAllOnChange(e, taskSearchResult?.data?.results),
        checked: taskSearchResult?.data?.results?.every((result) => selectedItems?.includes(result?.urn)) || false,
      }}
      listItemGenerator={(item: TaskSearchResultExternal, idx) => {
        return (
          <SelectListItem
            key={`${item?.urn}_${idx}`}
            title={item?.title}
            details={item?.taskRef || null}
            handleChange={() => setSelectedItems(toggleValueInList(item?.urn, selectedItems))}
            isChecked={selectedItems?.includes(item?.urn) || false}
            isSmallDevice={isSmallDevice}
            icon={
              <ListItemAvatar>
                <Icon
                  fullPath={item?.taskType?.icon?.iconPath}
                  size={IconSize.SM}
                  colorMask={{
                    size: '32px',
                    color: item?.taskType?.icon?.iconColourMask,
                  }}
                />
              </ListItemAvatar>
            }
          />
        );
      }}
    />
  );
};
