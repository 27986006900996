import React, { useState } from 'react';
import { Box, Typography, Tooltip } from '@mui/material';
import { TooltipState } from '@askporter/utils';
import { TitleField, OptionsDropdown, Button, Icon, IconSize, OptionsParams } from '../../';

export interface TitleHeaderProps {
  isSmallDevice: boolean;
  handleTitleBlur?: (onBlur?: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  hasAccess?: boolean | undefined;
  upperTitle?: React.ReactNode;
  title: string;
  subtitle: React.ReactNode;
  tooltipDataTestId?: string;
  inputDataTestId?: string;
  icon: React.ReactNode;
  optionsParams?: OptionsParams;
  renderContextMenu?: React.ReactNode;
  chips?: React.ReactNode;
  handleTooltipClose?: () => void;
  handleTooltipOpen?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  tooltipState?: TooltipState;
}

/**
 * Renders a Title Header component
 * @param isSmallDevice - Whether we are in single / split column view
 * @param handleTitleBlur - The handler for when the title loses focus
 * @param hasAccess - Establishes who can update the title or not
 * @param upperTitle - Secondary text above title
 * @param title - Entity title
 * @param subtitle - Secondary text / Chip under the title
 * @param tooltipDataTestId - Data test id for tooltip
 * @param inputDataTestId - Data test id for the title field
 * @param icon - The left hand side icon / avatar
 * @param optionsParams - Contains the title for the mobile drawer and menu options
 * @param renderContextMenu - Render the context menu
 * @param chips - a place for chips to be rendered, this is rendered on the left of the context menu
 */

export const TitleHeader: React.FC<React.PropsWithChildren<TitleHeaderProps>> = ({
  isSmallDevice,
  hasAccess,
  upperTitle,
  title,
  handleTitleBlur,
  subtitle,
  tooltipDataTestId = 'title-tooltip',
  inputDataTestId = 'title-input',
  icon,
  optionsParams,
  renderContextMenu,
  handleTooltipOpen,
  handleTooltipClose,
  tooltipState = { visible: false, message: '' },
  chips,
}) => {
  const [mobileView, setMobileView] = useState(false);

  return (
    <>
      <Box
        width="100%"
        display="flex"
        flexDirection="row"
        justifyContent={optionsParams || renderContextMenu ? 'space-between' : 'flex-start'}
        data-testid="title-header"
      >
        <Box
          display="flex"
          flexDirection="row"
          width={optionsParams || renderContextMenu ? 'calc(100% - 32px)' : '100%'}
        >
          {/* Icon */}
          <Box mr={4}>{icon}</Box>
          <Box>
            {/* Upper Title */}
            {isSmallDevice && mobileView ? (
              <></>
            ) : (
              <>
                {upperTitle && (
                  <Box>
                    <Typography color="textSecondary" variant="subtitle2" mb={0}>
                      {upperTitle}
                    </Typography>
                  </Box>
                )}
              </>
            )}

            {/* Title - text with tooltip or text field */}
            {!hasAccess ? (
              title && (
                <Box data-testid="task-title">
                  <Tooltip title={title} placement="bottom-start" aria-label={title}>
                    <Typography
                      variant="h6"
                      noWrap={true}
                      width="100%"
                      sx={{ cursor: 'default' }}
                      data-testid={tooltipDataTestId}
                    >
                      {title}
                    </Typography>
                  </Tooltip>
                </Box>
              )
            ) : (
              <Box>
                <TitleField
                  inputTestId={inputDataTestId}
                  variant="filled"
                  fullWidth={true}
                  defaultValue={title}
                  onClick={() => {
                    setMobileView(true);
                  }}
                  onBlur={(e) => {
                    handleTitleBlur(e);
                    setMobileView(false);
                  }}
                  aria-label={title}
                  sx={{
                    my: 0,
                    '.MuiInputBase-input': {
                      '&:focus': {
                        p: `${isSmallDevice ? '10px 4px' : '0 4px'}`,
                      },
                      p: '0',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    },
                  }}
                />
              </Box>
            )}

            {/* Subtitle */}
            {isSmallDevice && mobileView ? (
              <></>
            ) : (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography component="div" color="textSecondary" data-testid="subTitle">
                  {subtitle}
                </Typography>
                {hasAccess && handleTooltipOpen && handleTooltipClose && (
                  <Tooltip
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    placement="top"
                    arrow
                    PopperProps={{
                      disablePortal: true,
                    }}
                    open={tooltipState.visible}
                    title={tooltipState.message}
                    data-testid="copy-subtitle-tooltip"
                  >
                    <Button
                      sx={{ minWidth: 0 }}
                      onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                        handleTooltipOpen(e);
                        setTimeout(handleTooltipClose, 1500);
                      }}
                      data-testid="copy-subtitle-button"
                    >
                      <Icon size={IconSize.SM} folder="actions/05-copy" />
                    </Button>
                  </Tooltip>
                )}
              </Box>
            )}
          </Box>
        </Box>
        {chips}
        {optionsParams && (
          <Box width="32px">
            <OptionsDropdown
              optionsParams={optionsParams}
              isSmallDevice={isSmallDevice}
              iconStyles={{
                pt: 0,

                '&:hover': {
                  bgcolor: 'transparent',
                },

                '& > .MuiSvgIcon-root': {
                  borderRadius: '2px',
                  bgcolor: 'grey.200',
                },
              }}
              iconProps={{
                disableRipple: true,
              }}
              dataTestIdIconBtn="header-ellipsis-btn"
            />
          </Box>
        )}
        {renderContextMenu && renderContextMenu}
      </Box>
    </>
  );
};
