import React from 'react';
import { Check, Close as Cross } from '@mui/icons-material';
import { Box, List, ListItem, ListItemIcon, ListItemText, Popper, PopperPlacementType } from '@mui/material';
import { styled } from '@mui/material/styles';
import { TemporaryRequiredPropsFix } from '@askporter/utils';
import { PasswordCriteriaDisplay } from './types';
import { regexRules, checkUnmatchedRules } from './utils';

export interface PasswordCriteriaProps {
  display?: PasswordCriteriaDisplay;
  value: string;
  placement?: PopperPlacementType;
  open?: boolean;
  anchorEl?: HTMLElement;
  passwordRules: (t: (key: string, options?: Record<string, string | number>) => string) => Record<string, string>;
  t: (key: string, options?: Record<string, string | number>) => string;
  children?: React.ReactNode;
}

// Could not pass the 'sx' prop to Popper, hence this
const PasswordPopup = styled(Popper)(
  ({ theme }) => `
  z-index: ${theme.zIndex.tooltip};
`,
);

/**
 * Renders a component which shows whether the entered password
 * conforms to the password criteria (set of regex rules)
 *
 * @param display - whether the component is displayed inline or as a popup
 * @param value - the value to validate
 * @param placement - Popper placement options, default is 'top-start'
 * @param open - show or hide the component (if 'display' prop is set top 'popup')
 * @param anchorEl - where to anchor the component usually the password field
 *
 */
export const PasswordCriteria: React.FC<React.PropsWithChildren<PasswordCriteriaProps>> = ({
  display = 'inline',
  value = '',
  placement = 'top-start',
  open,
  anchorEl,
  passwordRules,
  children,
  t,
}: PasswordCriteriaProps) => {
  const errors = checkUnmatchedRules(value, regexRules);
  const content = (
    <Box data-testid="passwordCriteria" sx={{ textAlign: 'left' }}>
      {children}
      <List aria-label="password criteria" dense={true}>
        {Object.keys(passwordRules(t)).map((r) => (
          <ListItem key={r}>
            <ListItemIcon>
              {errors.includes(r) ? <Cross data-testid={`cross-${r}`} /> : <Check data-testid={`check-${r}`} />}
            </ListItemIcon>
            <ListItemText primary={passwordRules(t)[r]} />
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return display === 'inline' ? (
    <Box sx={{ textAlign: 'left' }}>{content}</Box>
  ) : (
    <PasswordPopup open={open} placement={placement} anchorEl={anchorEl} {...TemporaryRequiredPropsFix}>
      <Box
        sx={{
          textAlign: 'left',
          borderRadius: '5px',
          backgroundColor: 'background.paper',
          color: 'text.primary',
          p: 2,
        }}
      >
        {content}
      </Box>
    </PasswordPopup>
  );
};
