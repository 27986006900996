type LocalizedTimeFormat = () => 'HH:mm' | 'hh:mm A';
/**
 * A function to get localized time format
 * @returns mobiscroll timeFormat: 'HH:mm' or 'hh:mm A'
 */
export const getLocalizedTimeFormat: LocalizedTimeFormat = () => {
  // Get device timezone e.g. Europe/Berlin
  const timeZone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone;
  // For Berlin, return 24h format
  return timeZone === 'Europe/Berlin' ? 'HH:mm' : 'hh:mm A';
};
