import React from 'react';
import { UseQueryResult } from 'react-query';
import { usePermissions } from '@askporter/auth-provider';
import {
  FilterCount,
  FilterWrapper,
  CheckboxFilterItem,
  DateRangeFilter,
  FilterSection,
  LoadingButton,
  ExtendedMetadataFilters,
  AssetListCard,
} from '@askporter/component-library';
import {
  SearchUserResultExternal,
  SearchTeamResultExternal,
  SearchAssetResultExternal,
  TaskStatusType,
  SearchTaskFilter,
  SlaCurrentStatusReadOnly,
  TaskTypeGroupListExternalTaskTypeGroups,
  SearchTaskStats,
  SearchExtendedMetadataFilterCriteria,
  ExtendedMetadataAttributeFilterSummaryExternal,
} from '@askporter/grieg-types';
import { toggleValueInList } from '@askporter/utils';
import type { DisplayedTaskFilters, TaskFilterPermissions } from '@askporter/utils';
import {
  TaskTypeGroupProps,
  TransformedTaskFilterValues,
  Actions,
  TaskFilterErrors,
  ConfigBasedTaskFilterKeys,
} from '../../types';
import { ConfigBasedFilter } from '../ConfigBasedFilter';
import { SlaStatusCheckboxLabel } from '../SlaStatusCheckboxLabel';
import { TaskTypesFilter } from '../TaskTypesFilter';

export interface SearchFilter<SearchResultsType> {
  searchValue: string;
  onChange?: (value: string) => void;
  searchResults: SearchResultsType;
  isLoading: boolean;
  hasMore?: boolean;
  fetchMore?: () => void;
  moreResultsLoading?: boolean;
  totalResults?: number;
}

export interface FilterSectionsProps {
  filters: SearchTaskFilter;
  t: (key: string, options?: Record<string, string | number>) => string;
  filterPermissions: TaskFilterPermissions;
  actions: Actions;
  dateTimeOnChange: (key: string, target: string, value: string | SearchExtendedMetadataFilterCriteria) => void;
  linkedUser?: SearchFilter<SearchUserResultExternal['results']>;
  assignedUser?: SearchFilter<SearchUserResultExternal['results']>;
  assignedTeam?: SearchFilter<SearchTeamResultExternal['results']>;
  linkedAsset?: SearchFilter<SearchAssetResultExternal['results']>;
  dueSlaStatuses?: { value: SlaCurrentStatusReadOnly; label: string; count: number }[];
  responseSlaStatuses?: { value: SlaCurrentStatusReadOnly; label: string; count: number }[];
  resolveSlaStatuses?: { value: SlaCurrentStatusReadOnly; label: string; count: number }[];
  statusTypes?: { value: TaskStatusType; label: string }[];
  configBasedTaskFilterValues: Record<ConfigBasedTaskFilterKeys, TransformedTaskFilterValues>;
  configBasedFilterSearch: Record<ConfigBasedTaskFilterKeys, string>;
  configBasedFilterSearchOnChange: (e: React.ChangeEvent<HTMLInputElement>, filterKey: string) => void;
  errors: TaskFilterErrors;
  isSmallDevice: boolean;
  areFilterCountsLoading: boolean;
  configBasedTaskFilterValuesLoading: boolean;
  taskTypesQuery: UseQueryResult<TaskTypeGroupListExternalTaskTypeGroups[], unknown>;
  stats: SearchTaskStats;
  extendedMetadataFilters?: ExtendedMetadataAttributeFilterSummaryExternal[];
  TaskTypeGroup: React.FC<React.PropsWithChildren<TaskTypeGroupProps>>;
}

/**
 * Renders all filter sections for the tasks side bar
 * @param filters - filter state
 * @param t - translation function
 * @param filterPermissions - object describing the filter visibility
 * @param actions - reducer action functions
 * @param dateTimeOnChange - onChange handler for date time field
 * @param linkedUser - linked user object with searchValue string, onChange handler, and searchResults
 * @param assignedUser - assigned user object with searchValue string, onChange handler, and searchResults
 * @param assignedTeam - assigned team object with searchValue string, onChange handler, and searchResults
 * @param linkedAsset - linked asset object with searchValue string, onChange handler, and searchResults
 * @param responseSlaStatuses - array of response sla status value, labels and counts
 * @param resolveSlaStatuses - array of resolve sla status value, labels and counts
 * @param statusTypes -array of TakStatusTypes value and labels
 * @param configBasedTaskFilterValues - the retrieved values for config based task filters
 * @param configBasedFilterSearch - free text search state for config based filters
 * @param configBasedFilterSearchOnChange - onChange handler for config based filters
 * @param errors - object containing the error state of values retrieved from API calls
 * @param isSmallDevice - whether it's a small viewport or not
 * @param areFilterCountsLoading - whether we are still waiting for the filter counts to be calculated
 * @param configBasedTaskFilterValuesLoading - whether the GET /task-filter-values is loading
 * @param taskTypesQuery - the query object for task types, providing access to data, loading & error
 * @param extendedMetadataFilters - the extended metadata filters
 * @param TaskTypeGroup - the task type group component
 */
export const FilterSections: React.FC<React.PropsWithChildren<FilterSectionsProps>> = ({
  filters,
  t,
  filterPermissions,
  actions,
  dateTimeOnChange,
  linkedUser,
  assignedUser,
  assignedTeam,
  linkedAsset,
  statusTypes,
  configBasedTaskFilterValues,
  configBasedFilterSearch,
  configBasedFilterSearchOnChange,
  errors,
  isSmallDevice,
  areFilterCountsLoading,
  configBasedTaskFilterValuesLoading,
  taskTypesQuery,
  stats,
  extendedMetadataFilters,
  TaskTypeGroup,
  dueSlaStatuses,
  responseSlaStatuses,
  resolveSlaStatuses,
}: FilterSectionsProps) => {
  const showFilter: Record<DisplayedTaskFilters, boolean> = {
    linkedAsset: usePermissions(filterPermissions.linkedAsset),
    assignedUser: usePermissions(filterPermissions.assignedUser),
    assignedTeam: usePermissions(filterPermissions.assignedTeam),
    created: usePermissions(filterPermissions.created),
    dueStatus: usePermissions(filterPermissions.dueStatus),
    statusType: usePermissions(filterPermissions.statusType),
    priority: usePermissions(filterPermissions.priority),
    respondStatus: usePermissions(filterPermissions.respondStatus),
    resolveStatus: usePermissions(filterPermissions.resolveStatus),
    status: usePermissions(filterPermissions.status),
    taskType: usePermissions(filterPermissions.taskType),
    extendedMetadata: usePermissions(filterPermissions.extendedMetadata),
    dueSlaStatus: usePermissions(filterPermissions?.dueSlaStatus),
  };

  if (filters.linkedAsset?.length && linkedAsset?.searchResults?.length) {
    const isLinkedAssetInSearchResults = filters.linkedAsset.every((assetUid) =>
      linkedAsset.searchResults.some(({ uid }) => assetUid === uid),
    );
    if (!isLinkedAssetInSearchResults && linkedAsset.hasMore && !linkedAsset.moreResultsLoading) {
      linkedAsset.fetchMore();
    }
  }

  if (filters.linkedUser?.length && linkedUser?.searchResults?.length) {
    const isLinkedUserInSearchResults = filters.linkedUser.every((userUid) =>
      linkedUser.searchResults.some(({ uid }) => userUid === uid),
    );
    if (!isLinkedUserInSearchResults && linkedUser.hasMore && !linkedUser.moreResultsLoading) {
      linkedUser.fetchMore();
    }
  }

  if (filters.assignedUser?.length && assignedUser?.searchResults?.length) {
    const isAssignedUserInSearchResults = filters.assignedUser.every((userUid) =>
      assignedUser.searchResults.some(({ uid }) => userUid === uid),
    );
    if (!isAssignedUserInSearchResults && assignedUser.hasMore && !assignedUser.moreResultsLoading) {
      assignedUser.fetchMore();
    }
  }

  if (filters.assignedTeam?.length && assignedTeam?.searchResults?.length) {
    const isAssignedTeamInSearchResults = filters.assignedTeam.every((userUid) =>
      assignedTeam.searchResults.some(({ uid }) => userUid === uid),
    );
    if (!isAssignedTeamInSearchResults && assignedTeam.hasMore && !assignedTeam.moreResultsLoading) {
      assignedTeam.fetchMore();
    }
  }

  return (
    <>
      {/* Linked assets */}
      {showFilter.linkedAsset && (
        <FilterSection
          filterKey="linkedAsset"
          isLoading={linkedAsset.isLoading}
          t={t}
          filterSectionTitle={t('ns.task_filter:section:linked_asset')}
          searchLabel={t('ns.common:search:assets')}
          searchValue={linkedAsset.searchValue}
          searchOnChange={(e: React.ChangeEvent<HTMLInputElement>) => linkedAsset.onChange(e.currentTarget.value)}
          isExpandedOnMount={!!filters?.linkedAsset?.length}
          isError={errors.assetIsError}
        >
          {linkedAsset.searchResults?.map(({ uid, assetRef, title, location, assetType, media }, index) => (
            <CheckboxFilterItem
              key={uid}
              filterKey="linkedAsset"
              filterValue={{
                uid,
                displayName: title,
                userSelectedValue: filters.linkedAsset?.includes(uid),
              }}
              onClick={() => {
                actions.setFilters({ linkedAsset: toggleValueInList(uid, filters.linkedAsset) });
              }}
              index={index}
            >
              <AssetListCard
                t={t}
                uid={uid}
                assetRef={assetRef}
                title={title}
                postalCode={location?.postalCode}
                assetType={assetType}
                imagePath={media?.length > 0 ? media[0]?.file?.filePath : undefined}
                isFilterOption={true}
                isSmallDevice={isSmallDevice}
                plain
              />
            </CheckboxFilterItem>
          ))}
          {linkedAsset.hasMore && (
            <LoadingButton
              isLoading={linkedAsset.moreResultsLoading}
              onClick={() => linkedAsset.fetchMore()}
              variant="text"
              dataTestId="linkedAsset-filter-see-more"
            >
              {t('ns.common:filter:search:see_more', { totalResults: linkedAsset.totalResults })}
            </LoadingButton>
          )}
        </FilterSection>
      )}
      {/* Linked users  */}
      <FilterSection
        filterKey="linkedUser"
        t={t}
        isLoading={linkedUser.isLoading}
        filterSectionTitle={t('ns.task_filter:section:linked_user')}
        searchLabel={t('ns.task_filter:section:linked_user:search_label')}
        searchValue={linkedUser.searchValue}
        searchOnChange={(e: React.ChangeEvent<HTMLInputElement>) => linkedUser.onChange(e.currentTarget.value)}
        isExpandedOnMount={!!filters?.linkedUser?.length}
        isError={errors.userIsError}
      >
        {linkedUser.searchResults?.map(({ givenName, familyName, uid }, index) => (
          <CheckboxFilterItem
            key={index}
            filterKey="linkedUser"
            filterValue={{
              displayName: `${givenName} ${familyName}`,
              userSelectedValue: filters.linkedUser?.includes(uid),
            }}
            onClick={() => {
              actions.setFilters({ linkedUser: toggleValueInList(uid, filters.linkedUser) });
            }}
            index={index}
          />
        ))}
        {linkedUser.hasMore && (
          <LoadingButton
            isLoading={linkedUser.moreResultsLoading}
            onClick={() => linkedUser.fetchMore()}
            variant="text"
            dataTestId="linkedUser-filter-see-more"
          >
            {t('ns.common:filter:search:see_more', { totalResults: linkedUser.totalResults })}
          </LoadingButton>
        )}
      </FilterSection>
      {/* Assigned users  */}
      {showFilter.assignedUser && (
        <FilterSection
          filterKey="assignedUser"
          t={t}
          isLoading={assignedUser.isLoading}
          filterSectionTitle={t('ns.task_filter:section:assigned_user')}
          searchLabel={t('ns.task_filter:section:assigned_user:search_label')}
          searchValue={assignedUser.searchValue}
          searchOnChange={(e: React.ChangeEvent<HTMLInputElement>) => assignedUser.onChange(e.currentTarget.value)}
          isExpandedOnMount={!!filters?.assignedUser?.length}
          isError={errors.userIsError}
        >
          {assignedUser.searchResults?.map(({ givenName, familyName, uid }, index) => (
            <CheckboxFilterItem
              key={index}
              filterKey="assignedUser"
              filterValue={{
                displayName: `${givenName} ${familyName}`,
                userSelectedValue: filters.assignedUser?.includes(uid),
              }}
              onClick={() => {
                actions.setFilters({ assignedUser: toggleValueInList(uid, filters.assignedUser) });
              }}
              index={index}
            />
          ))}
          {assignedUser.hasMore && (
            <LoadingButton
              isLoading={assignedUser.moreResultsLoading}
              onClick={() => assignedUser.fetchMore()}
              variant="text"
              dataTestId="assignedUser-filter-see-more"
            >
              {t('ns.common:filter:search:see_more', { totalResults: assignedUser.totalResults })}
            </LoadingButton>
          )}
        </FilterSection>
      )}
      {/* Assigned teams  */}
      {showFilter.assignedTeam && (
        <FilterSection
          filterKey="assignedTeam"
          isLoading={assignedTeam.isLoading}
          t={t}
          filterSectionTitle={t('ns.task_filter:section:assigned_team')}
          searchLabel={t('ns.task_filter:section:assigned_team:search_label')}
          searchValue={assignedTeam.searchValue}
          searchOnChange={(e: React.ChangeEvent<HTMLInputElement>) => assignedTeam.onChange(e.currentTarget.value)}
          isExpandedOnMount={!!filters?.assignedTeam?.length}
          isError={errors.teamIsError}
        >
          {assignedTeam.searchResults?.map(({ name, uid }, index) => (
            <CheckboxFilterItem
              key={index}
              filterKey="assignedTeam"
              filterValue={{
                displayName: name,
                userSelectedValue: filters.assignedTeam?.includes(uid),
              }}
              onClick={() => {
                actions.setFilters({ assignedTeam: toggleValueInList(uid, filters.assignedTeam) });
              }}
              index={index}
            />
          ))}
          {assignedTeam.hasMore && (
            <LoadingButton
              isLoading={assignedTeam.moreResultsLoading}
              onClick={() => assignedTeam.fetchMore()}
              variant="text"
              dataTestId="assignedTeam-filter-see-more"
            >
              {t('ns.common:filter:search:see_more', { totalResults: assignedTeam.totalResults })}
            </LoadingButton>
          )}
        </FilterSection>
      )}
      {/* Created date */}
      {showFilter.created && (
        <FilterSection
          filterKey="created"
          t={t}
          filterSectionTitle={t('ns.task_filter:section:created')}
          isExpandedOnMount={!!(filters?.created?.after || filters?.created?.before)}
        >
          <DateRangeFilter
            t={t}
            isUrlQuerySensitive
            filterKey="created"
            afterValue={filters?.created?.after}
            beforeValue={filters?.created?.before}
            onChange={({ value, target }) => dateTimeOnChange('created', target, value)}
          />
        </FilterSection>
      )}
      {/* Due status */}
      {showFilter.dueStatus && (
        <FilterSection
          filterKey="dueStatus"
          t={t}
          filterSectionTitle={t('task_filter:section:sla:due_status:label')}
          isExpandedOnMount={
            !!(filters?.resolutionDueDate?.after || filters?.resolutionDueDate?.before || filters?.dueSlaStatus?.length)
          }
        >
          <DateRangeFilter
            t={t}
            isUrlQuerySensitive
            filterKey="dueStatus"
            controls={['calendar']}
            afterValue={filters?.resolutionDueDate?.after}
            beforeValue={filters?.resolutionDueDate?.before}
            onChange={({ value, target }) => {
              actions.setFilters({
                resolutionDueDate: {
                  ...filters.resolutionDueDate,
                  [target]: value,
                },
              });
            }}
          />
          {dueSlaStatuses.map(({ label, value: status, count }, index) => {
            if (status === 'NA' || status === 'PAUSED') return null;
            const { dueSlaStatus = [] } = filters;

            const isChecked = dueSlaStatus?.includes(status);
            return (
              <FilterWrapper key={`task_filter_section_due_status_${status}`}>
                <CheckboxFilterItem
                  filterKey={`dueStatus-${status.toLowerCase()}`}
                  filterValue={{
                    displayName: '',
                    userSelectedValue: isChecked,
                  }}
                  onClick={() => {
                    actions.setFilters(
                      isChecked
                        ? {
                            dueSlaStatus: [...dueSlaStatus?.filter((value) => value !== status)],
                          }
                        : {
                            dueSlaStatus: [...dueSlaStatus, status],
                          },
                    );
                  }}
                  index={index}
                >
                  <SlaStatusCheckboxLabel label={label} status={status} />
                </CheckboxFilterItem>
                <FilterCount count={count} testId={`dueStatus-${status.toLowerCase()}`} />
              </FilterWrapper>
            );
          })}
        </FilterSection>
      )}
      {/* Status type (Open/Closed) */}
      {showFilter.statusType && (
        <FilterSection
          filterKey="statusType"
          t={t}
          isLoading={areFilterCountsLoading}
          filterSectionTitle={t('ns.task_filter:section:status_type')}
          isExpandedOnMount={!!filters?.statusType?.length}
        >
          {statusTypes?.map(
            ({ value, label, count }: { value: TaskStatusType; label: string; count: number }, index) => (
              <FilterWrapper key={`task_filter_section_status_type_${value}`}>
                <CheckboxFilterItem
                  filterKey="statusType"
                  filterValue={{
                    uid: value,
                    displayName: label,
                    userSelectedValue: filters.statusType?.includes(value),
                  }}
                  onClick={() => {
                    actions.setFilters({ statusType: toggleValueInList(value, filters.statusType) });
                  }}
                  index={index}
                />
                <FilterCount count={count} />
              </FilterWrapper>
            ),
          )}
        </FilterSection>
      )}
      {/* Priority */}
      {showFilter.priority && (
        <ConfigBasedFilter
          filters={filters}
          isLoading={areFilterCountsLoading || configBasedTaskFilterValuesLoading}
          filterKey="priority"
          filterSearch={configBasedFilterSearch}
          searchOnChange={configBasedFilterSearchOnChange}
          t={t}
          taskFilterValues={configBasedTaskFilterValues}
          setFilters={actions.setFilters}
          isError={errors.taskFilterValuesIsError}
        />
      )}
      {/* Respond status */}
      {showFilter.respondStatus && (
        <FilterSection
          filterKey="respondStatus"
          t={t}
          filterSectionTitle={t('task_filter:section:sla:respond_status:label')}
          isExpandedOnMount={
            !!(
              filters?.responseDueDateTime?.after ||
              filters?.responseDueDateTime?.before ||
              filters?.responseSlaStatus?.length
            )
          }
        >
          <DateRangeFilter
            t={t}
            isUrlQuerySensitive
            filterKey="respondStatus"
            afterValue={filters?.responseDueDateTime?.after}
            beforeValue={filters?.responseDueDateTime?.before}
            onChange={({ value, target }) => {
              actions.setFilters({
                responseDueDateTime: {
                  ...filters.responseDueDateTime,
                  [target]: value,
                },
              });
            }}
          />
          {responseSlaStatuses.map(({ label, value: status, count }, index) => {
            if (status === 'NA') return null;
            const { responseSlaStatus = [] } = filters;

            const isChecked = responseSlaStatus?.includes(status);
            return (
              <FilterWrapper key={`task_filter_section_respond_status_${status}`}>
                <CheckboxFilterItem
                  filterKey={`respondStatus-${status.toLowerCase()}`}
                  filterValue={{
                    displayName: '',
                    userSelectedValue: isChecked,
                  }}
                  onClick={() => {
                    actions.setFilters(
                      isChecked
                        ? {
                            responseSlaStatus: [...responseSlaStatus?.filter((value) => value !== status)],
                          }
                        : {
                            responseSlaStatus: [...responseSlaStatus, status],
                          },
                    );
                  }}
                  index={index}
                >
                  <SlaStatusCheckboxLabel label={label} status={status} />
                </CheckboxFilterItem>
                <FilterCount count={count} testId={`respondStatus-${status.toLowerCase()}`} />
              </FilterWrapper>
            );
          })}
        </FilterSection>
      )}
      {/* Resolve status */}
      {showFilter.resolveStatus && (
        <FilterSection
          filterKey="resolveStatus"
          t={t}
          filterSectionTitle={t('task_filter:section:sla:resolve_status:label')}
          isExpandedOnMount={
            !!(
              filters?.resolutionDueDateTime?.after ||
              filters?.resolutionDueDateTime?.before ||
              filters?.resolutionSlaStatus?.length
            )
          }
        >
          <DateRangeFilter
            t={t}
            isUrlQuerySensitive
            filterKey="resolveStatus"
            afterValue={filters?.resolutionDueDateTime?.after}
            beforeValue={filters?.resolutionDueDateTime?.before}
            onChange={({ value, target }) => {
              actions.setFilters({
                resolutionDueDateTime: {
                  ...filters.resolutionDueDateTime,
                  [target]: value,
                },
              });
            }}
          />
          {resolveSlaStatuses.map(({ label, value: status, count }, index) => {
            if (status === 'NA') return null;
            const { resolutionSlaStatus = [] } = filters;

            const isChecked = resolutionSlaStatus?.includes(status);
            return (
              <FilterWrapper key={`task_filter_section_resolve_status_${status}`}>
                <CheckboxFilterItem
                  filterKey={`resolveStatus-${status.toLowerCase()}`}
                  filterValue={{
                    displayName: '',
                    userSelectedValue: isChecked,
                  }}
                  onClick={() => {
                    actions.setFilters(
                      isChecked
                        ? {
                            resolutionSlaStatus: [...resolutionSlaStatus?.filter((value) => value !== status)],
                          }
                        : {
                            resolutionSlaStatus: [...resolutionSlaStatus, status],
                          },
                    );
                  }}
                  index={index}
                >
                  <SlaStatusCheckboxLabel label={label} status={status} />
                </CheckboxFilterItem>
                <FilterCount count={count} testId={`resolveStatus-${status.toLowerCase()}`} />
              </FilterWrapper>
            );
          })}
        </FilterSection>
      )}
      {/* Status */}
      {showFilter.status && (
        <ConfigBasedFilter
          filters={filters}
          isLoading={areFilterCountsLoading || configBasedTaskFilterValuesLoading}
          filterKey="status"
          filterSearch={configBasedFilterSearch}
          searchOnChange={configBasedFilterSearchOnChange}
          t={t}
          taskFilterValues={configBasedTaskFilterValues}
          setFilters={actions.setFilters}
          isError={errors.taskFilterValuesIsError}
        />
      )}
      {/* TaskType */}
      {showFilter.taskType && (
        <TaskTypesFilter
          TaskTypeGroup={TaskTypeGroup}
          filter={filters?.taskType}
          stats={stats}
          setFilters={actions.setFilters}
          taskTypesQuery={taskTypesQuery}
          t={t}
        />
      )}
      {/* Extended metadata */}
      {showFilter.extendedMetadata && extendedMetadataFilters?.length > 0 ? (
        <ExtendedMetadataFilters
          extendedMetadataFilters={extendedMetadataFilters}
          filterCountsLoading={areFilterCountsLoading}
          filters={filters}
          setFilters={actions.setFilters}
          stats={stats}
          t={t}
        />
      ) : null}
    </>
  );
};
