import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { config, getCacheBuster, setCacheBuster } from '../utils';

type AvailableMethods = 'get' | 'post' | 'put';
interface APIConfig {
  baseUrl?: string;
  getAPIAuthToken: () => Promise<string>;
}

interface APIOptions {
  authHeader?: boolean;
  langHeader?: boolean;
  additionalHeaders?: AxiosRequestConfig['headers'];
  queryParams?: AxiosRequestConfig['params'];
  responseType?: AxiosRequestConfig['responseType'];
  payload?: AxiosRequestConfig['data'];
  isGrieg?: boolean;
  path: string;
}

interface RequestOptions extends APIOptions {
  method: AvailableMethods;
}

export interface Error {
  message: string;
}

export interface IAPIClient {
  get: (params: APIOptions) => Promise<AxiosResponse['data']>;
  post: (params: APIOptions) => Promise<AxiosResponse>;
  put: (params: APIOptions) => Promise<AxiosResponse>;
}

/**
 * Wrapper around 'axios' for executing API requests
 *
 * API Client config
 * @param baseUrl - optional - typically something like 'http://localhost:3333'. Requests will prepend this to the given path.
 * @param getAPIAuthToken - function that will return the Auth token to use in Authorization header
 *
 *
 * Request options
 *
 * @param method - 'get', 'post' or 'put'
 * @param path - relative path if 'isGrieg' is true otherwise absolute
 * @param authHeader - whether to pass the ID token from Cognito as part of the Authorization header
 * @param langHeader - whether to pass the current language as part of the 'x-ap-lang' header
 * @param responseType - the response type to expect from the request
 * @param additionalHeaders - any other additional headers to pass with the request
 * @param payload - payload for 'put' / 'post'
 * @param queryParams - query params to append to 'get' request
 * @param isGrieg - whether to prefix the path with the Grieg base path
 *
 * @returns Promise<AxiosResponse>
 */
const request = async (
  { baseUrl, getAPIAuthToken }: APIConfig,
  {
    method,
    path,
    authHeader = true,
    langHeader = true,
    additionalHeaders,
    payload,
    queryParams,
    responseType,
    isGrieg = true,
  }: RequestOptions,
) => {
  let token;
  const headers: Record<string, string> = {};

  if (authHeader && getAPIAuthToken) {
    token = await getAPIAuthToken();
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }
  }

  if (langHeader) {
    const selectedLanguage = localStorage.getItem('selectedLanguage') || 'en-GB';
    headers['x-ap-lang'] = selectedLanguage;

    try {
      const languageChanged = localStorage.getItem('languageChanged');
      if (languageChanged) {
        headers['Cache-Control'] = 'no-cache';
        headers['Pragma'] = 'no-cache';

        const maxAgeInMs = 6 * 60 * 60 * 1000; // 6 hours
        if (Date.now() > new Date(languageChanged).getTime() + maxAgeInMs) {
          localStorage.removeItem('languageChanged');
        }
      }
    } catch {
      // ignore
    }
  }

  if (isGrieg && method === 'get' && path === 'profile') {
    if (queryParams) queryParams['k'] = getCacheBuster();
    else queryParams = { k: getCacheBuster() };
  }

  const response = await axios({
    method,
    url: `${isGrieg ? baseUrl ?? config.API_URL : ''}${path}`,
    data: payload,
    params: queryParams,
    headers: {
      ...headers,
      ...additionalHeaders,
    },
    ...(responseType && { responseType }),
  });

  if (method === 'get' && response?.status === 202 && response?.data?.name === 'POLICY_NOT_READY') {
    return Promise.reject({ response });
  }

  // after a successful post to the profile endpoint we should bust the cache
  if (isGrieg && method === 'post' && path === 'profile' && response.status === 200) setCacheBuster();

  return method === 'get' ? response?.data : response;
};

export const APIClient = (config?: APIConfig): IAPIClient => {
  return ['get', 'post', 'put'].reduce(
    (prev, curr: AvailableMethods) => ({
      ...prev,
      [curr]: async (params: APIOptions) => request(config, { method: curr, ...params }),
    }),
    {},
  ) as IAPIClient;
};
