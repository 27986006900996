import React, { useContext, createContext, useState } from 'react';

/*
  This context is used to handle the display of the ExportInProgressToast and the ExportCompletedToast
*/
interface ExportContextProps {
  /**
   * Whether the export snackbar is open
   */
  isExportSnackbarOpen: boolean;
  /**
   * Function to set the export snackbar open state
   */
  setIsExportSnackbarOpen: (isExportSnackbarOpen: boolean) => void;
}

const ExportContext = createContext<ExportContextProps>({
  isExportSnackbarOpen: false,
  setIsExportSnackbarOpen: () => {},
});

/**
 * Hook for maintaing export states
 */
export const useExport = (): ExportContextProps => {
  const context = useContext(ExportContext);

  if (context === undefined) {
    throw new Error('ExportContext must be used within a ExportProvider component');
  }
  return context;
};

/**
 * As the provider will wrap components, it needs to accepts children
 */
interface ExportProviderProps {
  /**
   * React node as children
   */
  children: React.ReactNode;
}

interface ExportState {
  isExportSnackbarOpen: boolean;
}

export const ExportProvider = ({ children }: ExportProviderProps): JSX.Element => {
  const [exportContext, setExportContext] = useState<ExportState>({ isExportSnackbarOpen: false });

  return (
    <ExportContext.Provider
      value={{
        isExportSnackbarOpen: exportContext.isExportSnackbarOpen,
        setIsExportSnackbarOpen: (isExportSnackbarOpen: boolean) => setExportContext({ isExportSnackbarOpen }),
      }}
    >
      {children}
    </ExportContext.Provider>
  );
};
