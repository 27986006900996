import { SearchTaskFilter } from './SearchTaskFilter';
import { SearchTaskSort } from './SearchTaskSort';
import { TimezoneName } from './TimezoneName';

export interface TaskExportCreate {
  name?: string;
  timezone?: TimezoneName;
  fields?: Array<TaskExportCreate_FieldsEnum>;
  filter?: SearchTaskFilter;
  sort?: SearchTaskSort;
}

export type TaskExportCreate_FieldsEnum = 'TASK_RESOURCES';

export const TaskExportCreate_FieldsEnum = {
  TASKRESOURCES: 'TASK_RESOURCES' as TaskExportCreate_FieldsEnum,
};
