import packageJSON from '../../../../package.json';
const { hostname, protocol, port } = window.location;

export interface Config {
  ENV: string;
  API_URL: string;
  BUILD_TARGET_ENV: string;
  API_SUFFIX: string;
  TEMP_DIRECTLINE_SECRET: string;
  IS_CI: boolean;
  ENABLE_LOCAL_RUM: boolean;
  DEV_ASSISTANT_SECRET: string;
  DEV_DISABLE_REACT_TOOLS: boolean;
  IS_SERVED_FROM_MOCK_SERVER: boolean;
  APP_VERSION: string;
  IP_GEOLOCATION_API_KEY: string;
}

// Controlled by Webpack and Jest. Can be either of the following:
// 'development' - when running webpack dev server
// 'test' - when running jest
// 'production' - when building for production with webpack
const env = process.env.NODE_ENV;
const apiSuffix = '/api/v1/';
// API key for timezone api used in LocationForm
const ipGeoLocationApiKey = process.env.IP_GEOLOCATION_API_KEY;
const api = `${protocol}//${hostname}${port ? `:${port}` : ''}${apiSuffix}`;
// To be Deprecated
const directLineSecret = process.env.TEMP_DIRECTLINE_SECRET;
const buildTargetEnv = process.env.BUILD_TARGET_ENV;
const isCI = process.env.IS_CI === 'true';
const enableLocalRum = process.env.ENABLE_LOCAL_RUM === 'true';
// For running the assistant locally
// For local development only
const devAssistantSecret = process.env.DEV_ASSISTANT_SECRET;
// For disabling the react query tools if needed as I just keep moving it
const devDisableReactTools = process.env.DEV_DISABLE_REACT_TOOLS === 'true';

export const config: Config = {
  ENV: env,
  API_URL: api,
  BUILD_TARGET_ENV: enableLocalRum ? 'local' : buildTargetEnv,
  ENABLE_LOCAL_RUM: enableLocalRum,
  API_SUFFIX: apiSuffix,
  TEMP_DIRECTLINE_SECRET: directLineSecret,
  IS_CI: isCI,
  DEV_ASSISTANT_SECRET: devAssistantSecret,
  DEV_DISABLE_REACT_TOOLS: devDisableReactTools,
  IS_SERVED_FROM_MOCK_SERVER: hostname?.toLowerCase() === 'localhost' && port === '3333' ? true : false,
  APP_VERSION: packageJSON.version,
  IP_GEOLOCATION_API_KEY: ipGeoLocationApiKey,
};
