import React, { useState, useEffect } from 'react';
import { Search as SearchIcon } from '@mui/icons-material';
import { Box, BoxProps, OutlinedInput, FormControl, InputLabel, InputAdornment, IconButton } from '@mui/material';

export interface SearchBoxProps {
  /**
   * input label text
   */
  label: string;
  /**
   * fires when user changes input value
   */
  onChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  /**
   * fires when user hits enter or clicks the search icon
   */
  onSubmit: (searchBoxValue: string) => void;
  /**
   * starting value of the search box
   */
  startValue?: string;
  /**
   * search button aria label text
   */
  searchButtonAria?: string;
  /**
   * handles the differences between figma designs and older uses of this component
   */
  disableSelfSpacing?: boolean;
  /**
   * passes props to top the wrapper box
   */
  wrapperBoxProps?: BoxProps;
}

export const SearchBox: React.FC<React.PropsWithChildren<SearchBoxProps>> = ({
  label,
  onChange,
  onSubmit,
  startValue,
  searchButtonAria,
  disableSelfSpacing,
  wrapperBoxProps,
}: SearchBoxProps) => {
  const [searchText, setSearchText] = useState(startValue);
  const [hasFocus, setHasFocus] = useState(false);
  const shrinkLabel = hasFocus || searchText !== '' ? true : false;
  useEffect(() => setSearchText(startValue), [startValue]);

  const handleSearchBoxOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event);
    }
    setSearchText(event.currentTarget.value);
  };

  const handleSearchBoxKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onSubmit(searchText);
    }
  };

  const boxSpacingProps = disableSelfSpacing ? {} : { px: 6, pb: 6 };
  const formControlSpacingProps = disableSelfSpacing ? {} : { sx: { m: 0, mt: 2 } };

  return (
    <Box {...boxSpacingProps} {...wrapperBoxProps}>
      <FormControl fullWidth variant="outlined" {...formControlSpacingProps}>
        <InputLabel htmlFor="search-box-input" shrink={shrinkLabel} sx={{ pl: shrinkLabel ? 0 : 8 }}>
          {label}
        </InputLabel>
        <OutlinedInput
          id="search-box-input"
          label={label}
          value={searchText}
          onChange={handleSearchBoxOnChange}
          onKeyPress={handleSearchBoxKeyPress}
          notched={shrinkLabel}
          onFocus={() => setHasFocus(true)}
          onBlur={() => setHasFocus(false)}
          startAdornment={
            <InputAdornment position="end">
              <IconButton aria-label={searchButtonAria} edge="start" onClick={() => onSubmit(searchText)}>
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          }
          sx={{
            backgroundColor: '#fff',
          }}
        />
      </FormControl>
    </Box>
  );
};
