import { SearchOrgFilter } from './SearchOrgFilter';
import { SearchOrgSort } from './SearchOrgSort';
import { TimezoneName } from './TimezoneName';

export interface OrgExportCreate {
  name?: string;
  timezone?: TimezoneName;
  fields?: Array<OrgExportCreate_FieldsEnum>;
  filter?: SearchOrgFilter;
  sort?: SearchOrgSort;
}

export type OrgExportCreate_FieldsEnum = 'REGISTRATION_TOKEN';

export const OrgExportCreate_FieldsEnum = {
  REGISTRATIONTOKEN: 'REGISTRATION_TOKEN' as OrgExportCreate_FieldsEnum,
};
