import React from 'react';
import {
  ExtendedMetadataAttributeFilterSummaryExternal,
  ExtendedMetadataDataType,
  SearchAssetStats,
  SearchExtendedMetadataDateFilter,
  SearchExtendedMetadataStringFilter,
  SearchOrgStats,
  SearchStatsGenericValue,
  SearchTaskStats,
  SearchUserStats,
} from '@askporter/grieg-types';
import { toggleValueInList } from '@askporter/utils';
import type { EntitySearchFilters } from '@askporter/utils';
import { FilterWrapper, FilterCount, CheckboxFilterItem, DateRangeFilter, FilterSection } from '../../';

export interface ExtendedMetadataFilterProps {
  extendedMetadataFilters: ExtendedMetadataAttributeFilterSummaryExternal[];
  filterCountsLoading: boolean;
  filters: EntitySearchFilters;
  setFilters: (filters: EntitySearchFilters) => void;
  stats: SearchTaskStats | SearchAssetStats | SearchUserStats | SearchOrgStats;
  t: (key: string, options?: Record<string, string | number>) => string;
}

/** Renders ExtendedMetadataFilters */
export const ExtendedMetadataFilters: React.FC<React.PropsWithChildren<ExtendedMetadataFilterProps>> = ({
  extendedMetadataFilters,
  filterCountsLoading,
  filters,
  setFilters,
  stats,
  t,
}: ExtendedMetadataFilterProps) => {
  return (
    <>
      {extendedMetadataFilters?.map((filter) => {
        const jsonElName = filter?.qualifiedJsonElementName;
        const key = `extendedMetadata.${jsonElName}`;

        // if there are no options to show for a string filter then don't show it
        if (filter.dataType === ExtendedMetadataDataType.STRING && !filter?.validationRules?.allowedValues?.length)
          return null;

        // handle date and date time extended metadata filters
        if ([ExtendedMetadataDataType.DATE, ExtendedMetadataDataType.DATETIME].includes(filter.dataType)) {
          const typedFilter = filters?.extendedMetadata?.[jsonElName] as SearchExtendedMetadataDateFilter;

          return (
            <FilterSection
              key={key}
              filterKey={`filter_${jsonElName}`}
              t={t}
              filterSectionTitle={filter?.displayName}
              isExpandedOnMount={!!(typedFilter?.after || typedFilter?.before)}
            >
              <DateRangeFilter
                t={t}
                filterKey={jsonElName}
                afterValue={typedFilter?.after}
                beforeValue={typedFilter?.before}
                onChange={({ value, target }) =>
                  setFilters({
                    extendedMetadata: {
                      ...filters?.extendedMetadata,
                      [jsonElName]: { ...filters?.extendedMetadata?.[jsonElName], [target]: value },
                    },
                  })
                }
                controls={filter.dataType === ExtendedMetadataDataType.DATE ? ['calendar'] : ['calendar', 'time']}
              />
            </FilterSection>
          );
        } else if ([ExtendedMetadataDataType.STRING].includes(filter.dataType)) {
          const typedFilter = filters?.extendedMetadata?.[jsonElName] as SearchExtendedMetadataStringFilter;
          const typedStats = (stats?.[jsonElName] || []) as SearchStatsGenericValue;
          const selectedOptions = typedFilter?.in || [];
          const someOptsHaveCounts = typedStats?.length ? typedStats?.some(({ count }) => count > 0) : false;

          if (!someOptsHaveCounts) return null;
          return (
            <FilterSection
              key={key}
              filterKey={`filter_${jsonElName}`}
              t={t}
              filterSectionTitle={filter?.displayName}
              isExpandedOnMount={!!selectedOptions?.length}
              isLoading={filterCountsLoading}
            >
              {filter?.validationRules?.allowedValues?.map((value, index) => {
                const count = typedStats?.length
                  ? typedStats?.find(({ value: statValue }) => {
                      return statValue === value;
                    })?.count || 0
                  : 0;

                if (count === 0) return null;
                return (
                  <FilterWrapper key={`task_filter_section_status_type_${value}`}>
                    <CheckboxFilterItem
                      filterKey="statusType"
                      filterValue={{
                        uid: value,
                        displayName: value,
                        userSelectedValue: selectedOptions?.includes(value),
                      }}
                      onClick={() => {
                        setFilters({
                          extendedMetadata: {
                            ...filters?.extendedMetadata,
                            [jsonElName]: { in: toggleValueInList(value, selectedOptions) },
                          },
                        });
                      }}
                      index={index}
                    />
                    <FilterCount count={count} />
                  </FilterWrapper>
                );
              })}
            </FilterSection>
          );
        }
      })}
    </>
  );
};
