import React from 'react';
import { Box, Typography } from '@mui/material';

export interface ListPageHeaderProps {
  /**
   * A return button component
   */
  returnButton?: React.ReactNode;
  /**
   * the page title for example "The best list"
   */
  title: string | React.ReactNode;
  /**
   * the secondary text in a title, usually a count for example "(92)"
   */
  secondaryTitle?: string;
  /**
   * description text, displayed below the title
   */
  description?: string;
  /**
   * a react element that is aligned to the right of the title
   */
  rightElement?: React.ReactNode;
}

/** Renders a ListPageHeader component */
export const ListPageHeader: React.FC<React.PropsWithChildren<ListPageHeaderProps>> = ({
  title,
  secondaryTitle,
  description,
  rightElement,
  returnButton,
}: ListPageHeaderProps) => {
  return (
    <Box p={6} pt={returnButton ? 8 : undefined}>
      {returnButton ? returnButton : null}
      <Box display="flex" gap={2} alignItems="center">
        {typeof title === 'string' || title instanceof String ? <Typography variant="h5">{title}</Typography> : title}
        {secondaryTitle ? <Typography variant="subtitle1">{secondaryTitle}</Typography> : null}
        <Box alignSelf="flex-start" ml="auto">
          {rightElement}
        </Box>
      </Box>
      {description ? (
        <Typography variant="subtitle1" color="text.secondary">
          {description}
        </Typography>
      ) : null}
    </Box>
  );
};
