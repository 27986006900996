import React, { useEffect } from 'react';
import { ExportResourceNameFilterAndSortUnion } from '../../types';
import { ExportLimitDialog } from './ExportLimitDialog';
import { ExportModal } from './ExportModal';
import { handleExportModalWrapperOpen } from './utils';
import { useExportModalWrapper } from './utils/useExportModalWrapper';

type ExportModalWrapperProps = {
  /**
   * The translation function
   */
  t: (key: string, options?: Record<string, string | number>) => string;
  /**
   * Whether the device is small
   */
  isSmallDevice: boolean;
  /**
   * The total number of search results
   */
  totalSearchResults: number;
  /**
   * The handler for closing the modal
   */
  handleClose: () => void;
} & ExportResourceNameFilterAndSortUnion;

/**
 * The Export Modal Wrapper component that wraps the export limit dialog and export modal
 */
export const ExportModalWrapper: React.FC<ExportModalWrapperProps> = ({
  t,
  isSmallDevice,
  totalSearchResults,
  resourceName,
  filter,
  sort,
  handleClose,
}) => {
  const {
    exportLimitDialog: { isExportLimitDialogOpen, openExportLimitDialog, closeExportLimitDialog },
    exportModal: { isExportModalOpen, openExportModal },
  } = useExportModalWrapper();

  useEffect(() => {
    // When the component mounts, open the export correct modal
    handleExportModalWrapperOpen({
      totalSearchResults,
      openExportLimitDialog,
      openExportModal,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ExportLimitDialog
        t={t}
        isOpen={isExportLimitDialogOpen}
        onClose={handleClose}
        proceedWithExport={() => {
          closeExportLimitDialog();
          openExportModal();
        }}
        applyFilters={handleClose}
        isSmallDevice={isSmallDevice}
      />
      <ExportModal
        isOpen={isExportModalOpen}
        onClose={handleClose}
        isSmallDevice={isSmallDevice}
        t={t}
        cancelExport={handleClose}
        title={t(`${resourceName}:export_modal:title`)}
        {...({
          filter,
          sort,
          resourceName,
        } as ExportResourceNameFilterAndSortUnion)}
      />
    </>
  );
};
