import React, { useState } from 'react';
import {
  Autocomplete,
  AutocompleteProps,
  Box,
  Divider,
  FormControl,
  InputBase,
  InputLabel,
  List,
  ListItemButton,
  ListItemText,
  MenuItem,
  MenuItemProps,
  TextField,
} from '@mui/material';
import { SxProps } from '@mui/system';
import { AutocompleteDropdown, SelectItem } from '@askporter/utils';
import { Drawer, Select, SelectProps } from '../../';
import { renderOption } from './utils';

interface CommonProps {
  /*
   * @param idPrefix - prefix for all id's and labels, includes data-testid
   * */
  idPrefix: string;
  /*
   * @param isSmallDevice - true if small viewport
   */
  isSmallDevice: boolean;
  /**
   * @param wrapperStyles - optional style overrides for the box which wraps the entire component
   */
  wrapperStyles?: SxProps;
  /**
   * @param startAdornment - optional start adornment for the select dropdown
   */
  startAdornment?: SelectProps['startAdornment'];
}

type AutoCompProps = Omit<
  AutocompleteProps<unknown, undefined, boolean, undefined, 'div'>,
  'renderInput' | 'id' | 'data-testid' | 'inputValue' | 'onInputChange' | 'onChange'
>;

export interface ResponsiveSelectDropdownDrawer<T> {
  items: Array<SelectItem<T> & { disabled?: boolean; divider?: boolean; sx?: Record<string, unknown> }>;
  onSelect: (value: string | number | readonly string[], label?: string) => void;
  value: T;
  label: string;
}

export type StatusSelectorProps =
  | ({
      mode: 'select';
      selectDropdown: ResponsiveSelectDropdownDrawer<MenuItemProps['value']>;
    } & CommonProps)
  | ({
      mode: 'autocomplete';
      selectDropdown: AutocompleteDropdown;
    } & CommonProps &
      AutoCompProps);

/**
 * Renders a ResponsiveSelectDrawer component
 */
export const ResponsiveSelectDrawer: React.FC<React.PropsWithChildren<StatusSelectorProps>> = ({
  selectDropdown,
  idPrefix,
  isSmallDevice,
  wrapperStyles,
  startAdornment,
  mode = 'select',
  ...autocompleteProps
}: StatusSelectorProps) => {
  const [standardComponentOpen, setStandardComponentOpen] = useState(false);
  const [drawer, setDrawer] = useState(false); // mobile drawer visibility
  const [inputValue, setInputValue] = React.useState(''); // autocomplete search value

  const renderComponents = () => {
    const components = { mainComponent: <></>, mobileOpenComponent: <></> };

    if (mode === 'select') {
      const select = selectDropdown as ResponsiveSelectDropdownDrawer<MenuItemProps['value']>;
      // eslint-disable-next-line functional/immutable-data
      components.mainComponent = (
        <FormControl variant="filled" fullWidth>
          <InputLabel shrink id={`${idPrefix}-label`}>
            {select?.label}
          </InputLabel>
          <Select
            data-testid={`${idPrefix}-select-component`}
            labelId={`${idPrefix}-label`}
            id={`${idPrefix}-select`}
            value={select?.value}
            onChange={(event) => {
              select?.onSelect(event?.target?.value);
            }}
            displayEmpty
            defaultValue=""
            startAdornment={startAdornment}
            open={standardComponentOpen}
            onClick={isSmallDevice ? () => setDrawer(true) : undefined}
            onOpen={isSmallDevice ? undefined : () => setStandardComponentOpen(true)}
            onClose={() => setStandardComponentOpen(false)}
          >
            {select?.items?.map((option, index) => {
              const hideItem = option.hidden
                ? {
                    display: 'none',
                  }
                : {};

              const sx = option.sx ? option.sx : {};

              return option.divider ? (
                <Divider key={index} />
              ) : (
                <MenuItem
                  key={`${index}_${option?.value}_${option?.label}`}
                  value={option?.value}
                  data-testid={`${idPrefix}-select-item`}
                  sx={{ ...sx, ...hideItem }}
                  disabled={option.disabled === true}
                >
                  {option?.label}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      );

      // eslint-disable-next-line functional/immutable-data
      components.mobileOpenComponent = (
        <Drawer open={drawer} onClose={setDrawer} title={selectDropdown?.label} dataTestId={`${idPrefix}-drawer`}>
          <List data-testid={`${idPrefix}-select-open-mobile`}>
            {select?.items?.map((item, ind: number) => {
              const hideItem = item.hidden
                ? {
                    display: 'none',
                  }
                : {};

              const sx = item.sx ? item.sx : {};

              return item.divider ? (
                <Divider key={ind} />
              ) : (
                <ListItemButton
                  key={ind}
                  alignItems="flex-start"
                  onClick={() => {
                    select?.onSelect(item?.value);
                    setDrawer(false);
                  }}
                  dense={true}
                  selected={item?.value === select?.value}
                  data-testid={`${idPrefix}-select-item`}
                  sx={{ ...sx, ...hideItem }}
                  disabled={item.disabled === true}
                >
                  <ListItemText primary={item?.label} />
                  {item.divider ? <Divider /> : null}
                </ListItemButton>
              );
            })}
          </List>
        </Drawer>
      );
    }

    if (mode === 'autocomplete') {
      const autocompleteDropdown = selectDropdown as AutocompleteDropdown;
      const { options, ...autocomplete } = autocompleteProps as AutoCompProps;

      const commonAutocompleteProps = {
        defaultValue: '',
        options: options,
        'data-testid': `${idPrefix}-autocomplete-component`,
        id: `${idPrefix}-autocomplete`,
        open: standardComponentOpen,
        onOpen: isSmallDevice ? undefined : () => setStandardComponentOpen(true),
        onClose: () => setStandardComponentOpen(false),
        inputValue: inputValue,
        onInputChange: (_: React.SyntheticEvent, newInputValue: string) => {
          setInputValue(newInputValue);
        },
        onChange: (_: React.SyntheticEvent, newValue: SelectItem<MenuItemProps['value']>) => {
          isSmallDevice && setDrawer(false);
          autocompleteDropdown?.onChange(newValue?.value as string);
        },
        renderOption,
      };

      // eslint-disable-next-line functional/immutable-data
      components.mainComponent = (
        <Autocomplete
          {...autocomplete}
          {...commonAutocompleteProps}
          renderInput={(params) => (
            <TextField
              {...params}
              onClick={isSmallDevice ? () => setDrawer(true) : () => setStandardComponentOpen(true)}
              onKeyPress={isSmallDevice ? () => setDrawer(true) : undefined}
              variant="filled"
              label={autocompleteDropdown?.label}
              InputProps={{
                ...params.InputProps,
                startAdornment,
              }}
            />
          )}
        />
      );

      // eslint-disable-next-line functional/immutable-data
      components.mobileOpenComponent = (
        <Drawer
          open={drawer}
          onClose={setDrawer}
          title={selectDropdown?.label}
          dataTestId={`${idPrefix}-drawer`}
          sx={{
            '& .MuiDrawer-paperAnchorBottom': {
              position: 'fixed',
              top: 0,

              '& .MuiAutocomplete-listbox': {
                maxHeight: 'none',
              },
            },
          }}
        >
          <Autocomplete
            {...autocomplete}
            {...commonAutocompleteProps}
            disableCloseOnSelect
            disablePortal
            open={true}
            renderInput={(params) => (
              <InputBase
                ref={params.InputProps.ref}
                inputProps={params.inputProps}
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus
                sx={{
                  width: '100%',
                  '& input': {
                    borderRadius: 2,
                    backgroundColor: 'white',
                    padding: 2,
                    border: '1px solid #ced4da',
                    '&:focus': {
                      borderColor: 'primary.main',
                    },
                  },
                }}
              />
            )}
          />
        </Drawer>
      );
    }

    return components;
  };

  const components = renderComponents();

  return (
    <Box data-testid={`${idPrefix}-responsive-select`} width="100%" sx={{ ...wrapperStyles }}>
      {components.mainComponent}
      {isSmallDevice && components.mobileOpenComponent}
    </Box>
  );
};
