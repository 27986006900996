import {
  ExtendedMetadataAttributeFilterSummaryExternal,
  ExtendedMetadataDataType,
  SearchAssetFilter,
  SearchExtendedMetadataDateFilter,
  SearchExtendedMetadataStringFilter,
  SearchOrgFilter,
  SearchTaskFilter,
  SearchUserFilter,
} from '@askporter/grieg-types';

const typesThatNeedCounts = [
  // only string types use lists on the filters page, so these need counts
  ExtendedMetadataDataType.STRING,
];

/**
 * Converts the extended metadata array from a filter-values endpoint into a simple string list of atttributes that
 * should have counts
 *
 * @param extendedMetadataAttributeFilters - an array of ExtendedMetadataAttributeFilterSummaryExternal
 * @returns a string array of qualifiedJsonElementNames that can be sent with the POST search call
 */
export const getQualifiedStatNames = (
  extendedMetadataAttributeFilters: ExtendedMetadataAttributeFilterSummaryExternal[],
): string[] => {
  if (!extendedMetadataAttributeFilters) return null;

  const extendedMetadataStats = extendedMetadataAttributeFilters?.reduce(
    (prev, { dataType, qualifiedJsonElementName = '' }) => {
      if (typesThatNeedCounts.includes(dataType) && qualifiedJsonElementName)
        return [...prev, qualifiedJsonElementName];

      return prev;
    },
    [],
  );

  return extendedMetadataStats;
};

export type EntitySearchFilters = SearchTaskFilter | SearchAssetFilter | SearchUserFilter | SearchOrgFilter;

/**
 * Given an object of extended metadata filters, return a count of "applied filters" which can be used to determine
 * if filters are currently applied
 *
 * @param filters - some entity filter, either SearchTaskFilter, SearchAssetFilter, SearchUserFilter or SearchOrgFilter
 * @returns a number
 */
export const countAppliedExtendedMetadataFilters = (filters: EntitySearchFilters): number => {
  return Object.keys(filters?.extendedMetadata || {})?.reduce((acc, filterKey) => {
    try {
      if ('in' in filters?.extendedMetadata[filterKey]) {
        const filter = filters?.extendedMetadata[filterKey] as SearchExtendedMetadataStringFilter;
        return acc + filter?.in?.length || 0;
      }

      if ('before' in filters?.extendedMetadata[filterKey] || 'after' in filters?.extendedMetadata[filterKey]) {
        const filter = filters?.extendedMetadata[filterKey] as SearchExtendedMetadataDateFilter;
        return acc + (filter?.after || filter?.before ? 1 : 0);
      }
    } catch (_err) {
      return acc;
    }

    return acc;
  }, 0);
};
