import { createTheme, Theme } from '@mui/material';
import { TenantDisplayPalette } from '@askporter/grieg-types';
import { customPalette, customTypography, breakpoints, customComponents } from './overrides';

/*
  have to use module augmentation to add new variables to the Theme and ThemeOptions. This way we can have custom spacing, etc.
  official guide: https://mui.com/material-ui/customization/theming/#custom-variables
  stackoverflow example: https://stackoverflow.com/questions/58168798/add-custom-theme-variable-in-createtheme
*/

declare module '@mui/material/styles' {
  interface CustomTheme {
    custom?: {
      spacing?: {
        xxs: number;
        xs: number;
        sm: number;
        md: number;
        lg: number;
        xl: number;
        '2xl': number;
        '3xl': number;
        '4xl': number;
        '5xl': number;
        '6xl': number;
        '7xl': number;
        '8xl': number;
        '9xl': number;
        '10xl': number;
        '11xl': number;
      };
      radius?: {
        xxs: number;
        xs: number;
        sm: number;
        md: number;
        lg: number;
        xl: number;
        '2xl': number;
        '3xl': number;
        '4xl': number;
        full: number;
      };
    };
  }

  interface Theme extends CustomTheme {}
  interface ThemeOptions extends CustomTheme {}
}

/**
 * Creates the Material UI theme object with our design tokens
 *
 * @param configPalette - the tenant's colours conforming to the ConfigPalette type
 *
 * @returns Theme - the MUI Theme object
 */
export const createAPTheme = (configPalette: TenantDisplayPalette): Theme =>
  createTheme({
    components: customComponents(configPalette).components,
    breakpoints,
    spacing: (factor: number) => `${0.25 * factor}rem`,
    typography: customTypography.typography,
    palette: customPalette(configPalette),
    custom: {
      spacing: {
        xxs: 0.5,
        xs: 1,
        sm: 1.5,
        md: 2,
        lg: 3,
        xl: 4,
        '2xl': 5,
        '3xl': 6,
        '4xl': 8,
        '5xl': 10,
        '6xl': 12,
        '7xl': 16,
        '8xl': 20,
        '9xl': 24,
        '10xl': 32,
        '11xl': 40,
      },
      radius: {
        xxs: 0.5,
        xs: 1,
        sm: 1.5,
        md: 2,
        lg: 2.5,
        xl: 3,
        '2xl': 4,
        '3xl': 5,
        '4xl': 6,
        full: 9999,
      },
    },
  });

export { fallbackTheme } from './fallbackTheme';
export { fallbackPalette } from './fallbackPalette';
