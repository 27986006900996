import { TaskFilterValuesExternal } from '@askporter/grieg-types';
import { ConfigBasedTaskFilterKeys } from '../types';
export const configBasedTaskFilters: Array<ConfigBasedTaskFilterKeys> = ['priority', 'status'];

// mapping between the task-filter-values endpoint and the task search endpoint
export const taskFilterValuesMapping: Record<
  keyof Omit<TaskFilterValuesExternal, 'taskTypes' | 'extendedMetadata'>,
  ConfigBasedTaskFilterKeys
> = {
  priorities: 'priority',
  statuses: 'status',
};
