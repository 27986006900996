import React from 'react';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { Menu, MenuItem, Divider, MenuProps, Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { Icon, IconSize } from '../../../../';
import { IconLink, NavbarLink } from '../../utils';
interface AccountMenuProps extends MenuProps {
  t: (key: string) => string;
  handleSignOut: () => void;
  mainItems: Array<IconLink | NavbarLink>;
}

/** Renders a AccountMenu component */
const AccountMenu: React.FC<React.PropsWithChildren<AccountMenuProps>> = ({
  t,
  handleSignOut,
  mainItems,
  ...menuProps
}: AccountMenuProps) => {
  return (
    <Menu data-testid="menu" keepMounted {...menuProps}>
      {mainItems.map((item, idx) => {
        if ('href' in item)
          return (
            <MenuItem component={Link} to={item?.href} key={`${item?.href}_${item?.translation}`}>
              <Icon size={IconSize.MD} folder={item?.iconFolder} />
              <Typography ml={2} variant="subtitle1">
                {t(`ns.${item?.translation}`)}
              </Typography>
            </MenuItem>
          );
        else if ('hamburger' in item) return <Box key={`hamburger_item_${idx}`}>{item?.hamburger}</Box>;
      })}
      <MenuItem
        component={Link}
        to={{ pathname: '/app/account', state: { from: location.pathname } }}
        disabled={location.pathname.includes('account')}
        data-testid="my-account-btn"
      >
        <PersonOutlineIcon />
        <Typography ml={2} variant="subtitle1">
          {t('ns.global:header:nav:my_account')}
        </Typography>
      </MenuItem>
      <Divider />
      <MenuItem aria-label={t('ns.common:sign_out:button')} data-testid="sign-out" onClick={handleSignOut}>
        <LogoutIcon />
        <Typography ml={2} variant="subtitle1">
          {t('ns.global:header:nav:logout')}
        </Typography>
      </MenuItem>
    </Menu>
  );
};

export default AccountMenu;
