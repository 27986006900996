import { SearchAssetFilter } from './SearchAssetFilter';
import { SearchAssetSort } from './SearchAssetSort';
import { TimezoneName } from './TimezoneName';

export interface AssetExportCreate {
  name?: string;
  timezone?: TimezoneName;
  fields?: Array<AssetExportCreate_FieldsEnum>;
  filter?: SearchAssetFilter;
  sort?: SearchAssetSort;
}

export type AssetExportCreate_FieldsEnum =
  | 'ASSET_DETAILS'
  | 'ASSET_SETTINGS'
  | 'REGISTRATION_TOKEN'
  | 'ONSITE_ASSISTANT';

export const AssetExportCreate_FieldsEnum = {
  ASSETDETAILS: 'ASSET_DETAILS' as AssetExportCreate_FieldsEnum,
  ASSETSETTINGS: 'ASSET_SETTINGS' as AssetExportCreate_FieldsEnum,
  REGISTRATIONTOKEN: 'REGISTRATION_TOKEN' as AssetExportCreate_FieldsEnum,
  ONSITEASSISTANT: 'ONSITE_ASSISTANT' as AssetExportCreate_FieldsEnum,
};
