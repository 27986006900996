import React from 'react';
import { AssetSummaryExternal, AssetTypeSummaryExternal } from '@askporter/grieg-types';
import { copyToClipboard } from '@askporter/utils';
import { AssetAvatar, Breadcrumb, Breadcrumbs, Card, CopyBreadcrumb, calculateBreadcrumbProps } from '../../';

export interface AssetListCardProps {
  assetRef?: string;
  ancestors?: Array<AssetSummaryExternal>;
  isLoading?: boolean;
  isFilterOption?: boolean;
  title?: string;
  uid?: string;
  postalCode?: string;
  assetType?: AssetTypeSummaryExternal;
  imagePath?: string;
  cardOnClick?: (uid: string) => void;
  isSmallDevice: boolean;
  plain?: boolean;
  t?: (key: string, options?: Record<string, string | number>) => string;
  captureException?: (error: Error) => void;
}

export const AssetListCard: React.FC<React.PropsWithChildren<AssetListCardProps>> = ({
  assetRef,
  ancestors = [],
  isLoading,
  isFilterOption,
  title,
  uid,
  postalCode,
  assetType,
  imagePath,
  cardOnClick,
  isSmallDevice,
  plain = false,
  t,
  // eslint-disable-next-line no-console
  captureException = console.error,
}: AssetListCardProps) => {
  const copyFunction = () => {
    const isSuccess = copyToClipboard(`${window.location.origin}/app/assets/${uid}`, captureException);
    const message = isSuccess
      ? t('ns.asset_list:asset_link:clipboard_success')
      : t('ns.asset_list:asset_link:clipboard_error');

    return message;
  };

  const refBreadcrumb = assetRef
    ? [
        <CopyBreadcrumb
          key="asset-ref-breadcrumb"
          text={assetRef}
          copyFunction={copyFunction}
          aria-label={t('ns.asset_list:asset_link:aria_label')}
        />,
      ]
    : [];

  const breadcrumbs = [
    ...refBreadcrumb,
    ...ancestors?.map((ancestor) => {
      return <Breadcrumb key={`asset-card-breadcrumb-${ancestor?.uid}`} text={ancestor?.title} />;
    }),
  ].reverse();

  return (
    <Card
      onClick={cardOnClick ? () => cardOnClick(uid) : undefined}
      dataTestId={`${uid}-asset-list-card`}
      plain={plain}
    >
      {!isFilterOption && breadcrumbs?.length > 0 && (
        <Breadcrumbs
          onClick={(e) => e.stopPropagation()}
          {...calculateBreadcrumbProps({ breadcrumbs })}
          sx={{ alignSelf: 'center', mb: 4 }}
          variant="body2"
          color="text.secondary"
        >
          {breadcrumbs}
        </Breadcrumbs>
      )}
      <AssetAvatar
        title={title}
        assetRef={assetRef}
        assetType={assetType}
        postalCode={postalCode}
        imagePath={imagePath}
        uid={uid}
        isLoading={isLoading}
        isFilterOption={isFilterOption}
        isSmallDevice={isSmallDevice}
      />
    </Card>
  );
};
