import React, { Suspense, useEffect, useState } from 'react';
import axios, { AxiosError } from 'axios';
import { BrowserRouter } from 'react-router-dom';
import { CompatRouter } from 'react-router-dom-v5-compat';
import { Loading, ThemeProvider } from '@askporter/component-library';
import MaintenancePage from '../../pages/MaintenancePage';

interface Props {
  children: React.ReactNode[] | React.ReactNode;
}

/**
 * The MaintenanceModeDetector component aims to capture ALL Grieg 503s for requests made using Axios. The main known
 * exclusion is calls to the static/tenant made in the ConfigProvider, these fallback to a static maintenance page and
 * are triggered by the ConfigProvider.
 */
const MaintenanceModeDetector: React.FC<React.PropsWithChildren<Props>> = ({ children }: Props) => {
  const [shouldShowMaintenancePage, setShouldShowMaintenancePage] = useState(false);

  useEffect(() => {
    axios.interceptors.response.use(
      (response) => response, // do not do anything with 2xx responses
      (err: AxiosError) => {
        const wasGriegRequest = err?.config?.url?.includes(`${window.location.origin}/api`);
        const responseIs503 = err?.response?.status === 503;

        if (wasGriegRequest && responseIs503) setShouldShowMaintenancePage(true);

        return Promise.reject(err);
      },
    );
  }, []);

  return shouldShowMaintenancePage ? (
    <Suspense fallback={<Loading />}>
      <BrowserRouter>
        <CompatRouter>
          <ThemeProvider>
            <MaintenancePage />
          </ThemeProvider>
        </CompatRouter>
      </BrowserRouter>
    </Suspense>
  ) : (
    <>{children}</>
  );
};

export default MaintenanceModeDetector;
